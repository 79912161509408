import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import * as styles from "./file-upload.module.scss";
import { Box, Icon, Label, Spacer, Text, Tooltip } from "../../../../components";
import { classnames, useUid } from "../../../../utils";
import { openMediaPreview } from "../../../../modules/data-entry/utils";
import FilePreview from "../../../file-preview/file-preview";

interface FileUploadProps extends React.HTMLAttributes<HTMLInputElement> {
  label: string;
  type: string;
  onFileSelect: (file: File) => void;
  onFileDelete?: () => void;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  tooltipIcon?: string;
  tooltipText?: React.ReactNode;
  invalid?: boolean | undefined;
  errorMessage?: React.ReactNode;
  selectedMediaFile?: File;
}

export const FileUpload = ({
  disabled,
  errorMessage,
  label,
  name,
  onBlur,
  onFileDelete,
  onFileSelect,
  invalid,
  required,
  selectedMediaFile,
  tooltipText,
  tooltipIcon = "info",
  type,
  ...otherProps
}: FileUploadProps) => {
  const uid = useUid();
  const controlId = `input-file-${uid}`;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(selectedMediaFile);

  useEffect(() => {
    setSelectedFile(selectedMediaFile);
  }, [selectedMediaFile]);

  const handleFileSelect = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (!target.files?.length) {
      return;
    }

    const file = target?.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType.includes("image/") || fileType.includes("video/")) {
        onFileSelect(file);
        setSelectedFile(file);
      } else {
        alert("Please select a valid image or video file.");
      }
    }
  };

  const handleFileDelete = () => {
    setSelectedFile(undefined);
    typeof onFileDelete === "function" && onFileDelete();
  };

  return (
    <>
      <div className={styles.fileUploadContainer}>
        <input
          className={styles.fileUploadHiddenInput}
          type="file"
          ref={fileInputRef}
          accept={`${type === "image" ? "image/*" : "video/*"}`}
          onChange={handleFileChange}
          required={required}
        />

        <Label htmlFor={controlId} required={required} invalid={invalid}>
          {label}
        </Label>

        <div className={styles.field}>
          <div className={styles.inputWrapper}>
            <input
              {...otherProps}
              type="text"
              id={controlId}
              name={name}
              className={classnames({
                [styles.fileUploadInput]: true,
                [styles.invalid]: !!invalid,
              })}
              readOnly
              onClick={disabled ? undefined : handleFileSelect}
              onBlur={onBlur}
              disabled={disabled}
              required={required}
              value={selectedFile ? selectedFile.name : ""}
            />
            <div
              className={classnames({
                [styles.fileUploadIcon]: true,
                [styles.disabled]: !!disabled,
              })}
              onClick={disabled ? undefined : handleFileSelect}
            >
              <Icon name="upload" size="s" />
            </div>
          </div>

          {!!tooltipText && (
            <div className={styles.tooltip}>
              <Tooltip placement="top" text={tooltipText}>
                <Icon name={tooltipIcon || "info"} size="m" />
              </Tooltip>
            </div>
          )}
        </div>

        {errorMessage && invalid && (
          <Box>
            <Spacer size="xxxs" />
            <Text kind="error" size="small">
              {errorMessage}
            </Text>
          </Box>
        )}
      </div>

      {selectedFile && (
        <FilePreview selectedFile={selectedFile} onFileDelete={handleFileDelete} onFileView={openMediaPreview} />
      )}
    </>
  );
};
