import i18next from "i18next";
import type { i18n, InitOptions, TFunction } from "i18next";
import translationDe from "./locales/de/translation.json";

let i18nextPromise: Promise<TFunction> | null = null;

/**
 * Currently only the German language is supported.
 * As for localization this means we don't have to take care of language detection or multiple languages.
 */
const initI18n = async (): Promise<i18n> => {
  if (!i18nextPromise) {
    i18nextPromise = i18next.init(
      {
        debug: false,
        ns: ["translation"],
        lng: "de",
        fallbackLng: "de",
        supportedLngs: ["de"],
        resources: {
          de: {
            translation: translationDe,
          },
        },
      } as InitOptions,
      async (err) => {
        if (err) {
          console && console.error(err);
        }
      }
    );
  }
  await i18nextPromise;
  return i18next;
};

export default initI18n;
