import { InviteAssociationFormData } from "../../modules/invite/types";
import { IInviteAssociationResponse } from "./types";
import { getApiBaseUrl } from "../utils";
import { api } from "../api";
import { ApiResponse, ApiResponseError } from "src/backend/types";

const INVITE_ASSOCIATION_API_URL = `${getApiBaseUrl()}/api/v1/association`;

export const postInviteAssociation = async (
  data: InviteAssociationFormData
): Promise<ApiResponse<any, ApiResponseError>> => {
  return await api<IInviteAssociationResponse>(INVITE_ASSOCIATION_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  });
};
