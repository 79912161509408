import { AssociationCommentModel, AssociationModel } from "../../backend";
import { getApiBaseUrl } from "../utils";

const API_ENDPOINT = `${getApiBaseUrl()}/api/v1/association`;

export const getAssociationComments = async (associationId: string | number): Promise<AssociationCommentModel[]> => {
  let data: AssociationModel | null = null;
  const res = await fetch(`${API_ENDPOINT}/${associationId}`, {
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  });
  if (res && res.ok) {
    data = (await res.json()) as AssociationModel;
  }
  return data?.comments || [];
};

export const postAssociationComment = async (associationId: string | number, message: string): Promise<Response> => {
  return await fetch(`${API_ENDPOINT}/${associationId}/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ message }),
  });
};
