import React from "react";
import { useUid } from "../../../utils";
import { Box, Spacer } from "../layout";
import { Text } from "../text";
import { Label } from "../label";
import { Checkbox, CheckboxProps } from "./checkbox";
import * as styles from "./checkbox-field.module.scss";

export interface CheckboxFieldProps extends CheckboxProps {
  errorMessage?: React.ReactNode;
  label: React.ReactNode;
}

export const CheckboxField = ({
  disabled,
  errorMessage,
  id,
  invalid,
  label,
  required,
  ...otherProps
}: CheckboxFieldProps) => {
  const uid = useUid();
  const controlId = `checkbox-${uid}`;
  const errorId = `checkbox-error-${uid}`;

  return (
    <Box id={id}>
      <div className={styles.wrapper}>
        <Checkbox
          {...otherProps}
          required={required}
          disabled={disabled}
          id={controlId}
          invalid={invalid}
          aria-describedby={errorMessage && invalid ? errorId : undefined}
        />
        <Spacer size="xxs" />
        <Label htmlFor={controlId} disabled={disabled} required={required}>
          {label}
        </Label>
      </div>
      {errorMessage && invalid && (
        <>
          <Spacer size="xxxs" />
          <Text kind="error" size="small">
            {errorMessage}
          </Text>
        </>
      )}
    </Box>
  );
};
