import * as React from "react";
import { useTranslation } from "../../i18n";
import { Box, Cell, Dropdown, Spacer, Text } from "../dm";
import { PaginationComponentProps } from "./types";
import * as styles from "./pagination.module.scss";

export interface PaginationProps extends PaginationComponentProps {
  loading?: boolean;
  i18nNamespace?: string;
  filter?: React.ReactNode;
}

interface DropdownOption {
  label: string;
  value: string;
}

export const Pagination = ({ pagination, setPage, loading, filter, i18nNamespace = "pagination" }: PaginationProps) => {
  const [options, setOptions] = React.useState<DropdownOption[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!pagination) {
      return;
    }
    const pageOptions: DropdownOption[] = [];
    for (let i = 0; i < pagination.last_page; i++) {
      let from = i * pagination.per_page + 1;
      let to = Math.min(pagination.total, from + pagination.per_page - 1);
      pageOptions.push({
        label: t(`${i18nNamespace}.range`, { from, to }),
        value: `${i + 1}`,
      });
    }
    setOptions(pageOptions);
  }, [pagination, i18nNamespace, t]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!setPage) {
        return;
      }
      const page = parseInt(event.target.value);
      if (!Number.isNaN(page) && page > 0) {
        setPage(page);
      }
    },
    [setPage]
  );

  const renderSummary = (): React.ReactNode => {
    let text: React.ReactNode;
    if (loading) {
      text = t(`${i18nNamespace}.loading`);
    } else if (pagination) {
      text =
        pagination.total < 1
          ? t(`${i18nNamespace}.noResults`)
          : t(`${i18nNamespace}.summary`, { page: pagination.current_page, lastPage: pagination.last_page });
    }
    return text;
  };

  return (
    <Box>
      <Spacer size="xs" />
      <Cell className={styles.container}>
        <Text>{renderSummary()}</Text>
        <Box className={styles.controls}>
          {filter}
          {pagination && pagination.total > 0 && options.length > 0 && (
            <Dropdown
              options={options as DropdownOption[]}
              value={`${pagination?.current_page || 1}`}
              onChange={handleChange}
              disabled={loading}
            />
          )}
        </Box>
      </Cell>
      <Spacer size="xs" />
    </Box>
  );
};
