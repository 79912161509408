import { Dispatch, SetStateAction, createContext } from "react";
import { AssociationReadData } from "../../backend/auth-token/types";

export interface IAuthTokenContext {
  authToken: string;
  setAuthToken: Dispatch<SetStateAction<string>>;
}

export interface IAssociationReadDataContext {
  associationReadData: AssociationReadData | undefined;
  setAssociationReadData: Dispatch<SetStateAction<AssociationReadData | undefined>>;
}

export const AuthTokenContext = createContext<IAuthTokenContext>({
  authToken: "",
  setAuthToken: () => {},
});

export const AssociationReadDataContext = createContext<IAssociationReadDataContext>({
  associationReadData: undefined,
  setAssociationReadData: () => {},
});
