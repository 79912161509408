import { getApiBaseUrl } from "../utils";
import { ApiResponse } from "../types";
import { api } from "../api";
import { AssociationCreationLimits } from "./types";

const API_ENDPOINT = `${getApiBaseUrl()}/api/v1/association/check-limit`;

export const getCheckLimit = async (): Promise<ApiResponse<AssociationCreationLimits[]>> => {
  return await api<AssociationCreationLimits[]>(API_ENDPOINT, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  });
};
