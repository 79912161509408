import * as React from "react";
import { classnames } from "../../../utils";
import { Icon } from "../icon";
import { Spinner } from "../loading";
import * as styles from "./image.module.scss";

interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  alt: string;
  aspectRatio?: "1/1" | "4/3" | "3/4" | "16/9" | "9/16" | undefined;
  lazy?: boolean | undefined;
  src: string;
}

export const Image: React.FC<ImageProps> = ({ alt = "", aspectRatio, className, lazy, src, ...otherProps }) => {
  const [imgSrc, setImgSrc] = React.useState<string | null>(lazy ? null : src);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    let timeout: number;
    if (lazy && src) {
      timeout = window.setTimeout(() => {
        setImgSrc(src);
      }, 100);
    }
    return () => {
      window.clearTimeout(timeout);
    };
  }, [lazy, src]);

  const aspectClassNames: Record<string, boolean> = {
    [styles.aspect11]: aspectRatio === "1/1",
    [styles.aspect169]: aspectRatio === "16/9",
    [styles.aspect916]: aspectRatio === "9/16",
    [styles.aspect43]: aspectRatio === "4/3",
    [styles.aspect34]: aspectRatio === "3/4",
  };

  const handleLoad = () => {
    window.requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  };

  return (
    <div className={styles.container}>
      {
        <div
          className={classnames({
            [`${className}`]: !!className,
            [styles.placeholder]: true,
            [styles.loaded]: isLoaded,
            [styles.loading]: !isLoaded,
            ...aspectClassNames,
          })}
        >
          {!imgSrc && <Icon name="upload" size="xl" />}
          {imgSrc && !isLoaded && <Spinner />}
        </div>
      }
      {imgSrc && (
        <img
          alt={alt}
          className={classnames({
            [`${className}`]: !!className,
            [styles.img]: true,
            [styles.lazy]: !!lazy,
            [styles.loaded]: isLoaded,
            [styles.loading]: !isLoaded,
            ...aspectClassNames,
          })}
          src={imgSrc}
          onLoad={handleLoad}
          {...otherProps}
        />
      )}
    </div>
  );
};
