import React from "react";
import AngleDownIcon from "./assets/AngleDownIcon.svg";
import AngleUpIcon from "./assets/AngleUpIcon.svg";
import ArrowDownIcon from "./assets/ArrowDownIcon.svg";
import ArrowLeftIcon from "./assets/ArrowLeftIcon.svg";
import ArrowRightIcon from "./assets/ArrowRightIcon.svg";
import ArrowUpIcon from "./assets/ArrowUpIcon.svg";
import CheckIcon from "./assets/CheckIcon.svg";
import CheckCircleIcon from "./assets/CheckCircleIcon.svg";
import CheckCircleInvertedIcon from "./assets/CheckCircleInvertedIcon.svg";
import CrossIcon from "./assets/CrossIcon.svg";
import CrossCircleIcon from "./assets/CrossCircleIcon.svg";
import DocumentsIcon from "./assets/DocumentsIcon.svg";
import DownloadIcon from "./assets/DownloadIcon.svg";
import ExclamationCircleIcon from "./assets/ExclamationCircleIcon.svg";
import ExclamationCircleStrokeIcon from "./assets/ExclamationCircleStrokeIcon.svg";
import EyeIcon from "./assets/EyeIcon.svg";
import EyeStrokeIcon from "./assets/EyeStrokeIcon.svg";
import InfoIcon from "./assets/InfoIcon.svg";
import InfoStrokeIcon from "./assets/InfoStrokeIcon.svg";
import MailIcon from "./assets/MailIcon.svg";
import MinusIcon from "./assets/MinusIcon.svg";
import PlayIcon from "./assets/PlayIcon.svg";
import PlusIcon from "./assets/PlusIcon.svg";
import PlusCircleIcon from "./assets/PlusCircleIcon.svg";
import QuadArrowRightIcon from "./assets/QuadArrowRightIcon.svg";
import SearchIcon from "./assets/SearchIcon.svg";
import StoreIcon from "./assets/StoreIcon.svg";
import StoreCheckIcon from "./assets/StoreCheckIcon.svg";
import StoreColorIcon from "./assets/StoreColorIcon.svg";
import TelephoneIcon from "./assets/TelephoneIcon.svg";
import TrashIcon from "./assets/TrashIcon.svg";
import UploadIcon from "./assets/UploadIcon.svg";
import UserCheckIcon from "./assets/UserCheckIcon.svg";
import UserIcon from "./assets/UserIcon.svg";

import FacebookIcon from "./assets/socials/FacebookIcon.svg";
import InstagramIcon from "./assets/socials/InstagramIcon.svg";
import LinkedInIcon from "./assets/socials/LinkedinIcon.svg";
import KununuIcon from "./assets/socials/KununuIcon.svg";
import NewsletterIcon from "./assets/socials/NewsletterIcon.svg";
import PinterestIcon from "./assets/socials/PinterestIcon.svg";
import TiktokIcon from "./assets/socials/TiktokIcon.svg";
import WhatsappIcon from "./assets/socials/WhatsappIcon.svg";
import XingIcon from "./assets/socials/XingIcon.svg";
import YoutubeIcon from "./assets/socials/YoutubeIcon.svg";

const IconRegistry: Record<string, React.ElementType> = {
  angleDown: AngleDownIcon,
  angleUp: AngleUpIcon,
  arrowDown: ArrowDownIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowUp: ArrowUpIcon,
  check: CheckIcon,
  checkCircle: CheckCircleIcon,
  checkCircleInverted: CheckCircleInvertedIcon,
  cross: CrossIcon,
  crossCircle: CrossCircleIcon,
  documents: DocumentsIcon,
  download: DownloadIcon,
  eye: EyeIcon,
  eyeStroke: EyeStrokeIcon,
  exclamationCircle: ExclamationCircleIcon,
  exclamationCircleStroke: ExclamationCircleStrokeIcon,
  info: InfoIcon,
  infoStroke: InfoStrokeIcon,
  mail: MailIcon,
  minus: MinusIcon,
  play: PlayIcon,
  plus: PlusIcon,
  plusCircle: PlusCircleIcon,
  quadArrowRight: QuadArrowRightIcon,
  search: SearchIcon,
  store: StoreIcon,
  storeCheck: StoreCheckIcon,
  storeColor: StoreColorIcon,
  telephone: TelephoneIcon,
  trash: TrashIcon,
  upload: UploadIcon,
  user: UserIcon,
  userCheck: UserCheckIcon,

  facebook: FacebookIcon,
  instagram: InstagramIcon,
  kununu: KununuIcon,
  linkedin: LinkedInIcon,
  newsletter: NewsletterIcon,
  pinterest: PinterestIcon,
  tiktok: TiktokIcon,
  whatsapp: WhatsappIcon,
  xing: XingIcon,
  youtube: YoutubeIcon,
};

export const registeredIconNames = Object.keys(IconRegistry);

export default IconRegistry;
