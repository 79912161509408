import { ApiResponse, ApiResponseError } from "./types";

export const api = async <T = any, E = ApiResponseError>(
  url: string,
  options?: RequestInit
): Promise<ApiResponse<T, E>> => {
  let data: T;
  let error: E;
  let response: Response;
  try {
    response = await fetch(url, options);
  } catch (e) {
    response = new Response(null, {
      status: e.status,
      statusText: e.message,
    }) as Response;
  }
  if (response.ok) {
    try {
      data = (await response.json()) as T;
    } catch {
      // No response data
    }
  } else {
    try {
      error = (await response.json()) as E;
    } catch {
      // No response data
    }
  }
  return {
    ...response,
    ok: response.ok,
    json: () => data || error,
    data,
    error,
  };
};
