import * as React from "react";
import { useUser } from "../../contexts";
import { Footer } from "./footer";
import { Header } from "./header";
import * as styles from "./page.module.scss";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

export const Page: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const { pathname } = useLocation();

  if (pathname === "/downloads-partnerprojekte/")
    return (
      <>
        <div className={styles.wrapper}>
          <a href="https://dm.de/impressum" target="_blank" rel="noreferrer" className={styles.link}>
            Impressum
          </a>
          <Link to="/datenschutz" className={styles.link}>
            Datenschutzerklärung
          </Link>
        </div>
        <Header key={user ? "logged-in" : "logged-out"} />
        <main className={styles.main}>{children}</main>
        <Footer pressKitFooter />
      </>
    );

  return (
    <>
      <Header key={user ? "logged-in" : "logged-out"} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
};
