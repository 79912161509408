import React from "react";
import { Button, Icon } from "../dm";
import { classnames } from "../../utils";
import * as styles from "./file-preview.module.scss";

interface IFilePreviewProps {
  selectedFile?: File | undefined;
  src?: string | undefined;
  onFileDelete?: () => void;
  onFileView?: (file: File) => void;
  thumbnailSize?: "s" | "m";
}

const getFileType = (fileName: string): "image" | "video" | "document" => {
  const suffix = fileName.split(".").pop() || "";
  if (["jpg", "jpeg", "png", "bmp", "gif"].includes(suffix)) {
    return "image";
  }
  if (["mov", "mp4", "mpeg", "avi"].includes(suffix)) {
    return "video";
  }
  return "document";
};

const FilePreview = ({ selectedFile, src, onFileDelete, onFileView, thumbnailSize = "s" }: IFilePreviewProps) => {
  const imgSrc = selectedFile ? URL.createObjectURL(selectedFile) : src;
  if (!imgSrc) {
    return null;
  }
  const imgName = (selectedFile?.name || imgSrc).split("/").pop();
  const fileType = getFileType(imgName || "");

  const handleClick = () => {
    if (selectedFile && onFileView) {
      onFileView(selectedFile);
    }
  };

  const renderPreview = () => (
    <span className={styles.preview}>
      {fileType === "image" && (
        <img
          src={imgSrc}
          alt={selectedFile ? selectedFile.name : ""}
          className={classnames({
            [styles.image]: true,
            [styles[thumbnailSize]]: !!styles[thumbnailSize],
          })}
        />
      )}
      {fileType === "video" && <Icon name="play" />}
      {fileType === "document" && <Icon name="documents" />}
      <span>{imgName}</span>
    </span>
  );

  return (
    <div className={styles.container}>
      {selectedFile && (
        <button type="button" className={styles.link} onClick={handleClick}>
          {renderPreview()}
        </button>
      )}
      {src && (
        <a href={`${src}?time=${Date.now()}`} target="_blank" className={styles.link} rel="noreferrer">
          {renderPreview()}
        </a>
      )}
      {onFileDelete && (
        <Button type="button" icon="trash" kind="invisible" aria-label="Delete file" onClick={onFileDelete} />
      )}
    </div>
  );
};

export default FilePreview;
