import React from "react";
import { useUser } from "../user";
import {
  AssociationListPaginationResponse,
  AssociationListResponse,
  AssociationListStatistics,
  AssociationModel,
  getAssociationList,
} from "../../backend";
import { AssociationContextType } from "./interface";
import { AssociationContext } from "./context";
import { mockData } from "./mock-data";

interface AssociationProviderProps {
  defaultAssociations?: AssociationModel[];
  withMockData?: boolean;
}

export const AssociationProvider: React.FC<React.PropsWithChildren<AssociationProviderProps>> = ({
  defaultAssociations,
  withMockData,
  children,
}) => {
  const { loggedIn } = useUser();
  const [statistics, setStatistics] = React.useState<AssociationListStatistics | null>(null);
  const [associations, setAssociations] = React.useState<AssociationModel[]>(
    Array.isArray(defaultAssociations) ? defaultAssociations : withMockData ? mockData : []
  );

  const getAssociations = React.useCallback(async () => {
    let data: AssociationModel[] = [];
    if (!loggedIn) {
      return data;
    }
    const res = (await getAssociationList()) as AssociationListResponse & AssociationListPaginationResponse;
    if (!res) {
      setAssociations([]);
      return data;
    }
    if (Array.isArray(res.data)) {
      setAssociations(res.data);
    } else {
      setAssociations([]);
    }
    if (res.meta) {
      setStatistics(res.meta.statistics);
    } else if (res.statistics) {
      setStatistics(res.statistics);
    }
  }, [loggedIn]);

  React.useEffect(() => {
    if (loggedIn) {
      getAssociations();
    }
  }, [loggedIn, getAssociations]);

  const value = React.useMemo<AssociationContextType>(
    () => ({
      associations,
      getAssociations,
      statistics,
      setStatistics,
    }),
    [associations, getAssociations, statistics]
  );

  return <AssociationContext.Provider value={value}>{children}</AssociationContext.Provider>;
};
