import React from "react";
import { classnames } from "../../../utils";
import { Icon } from "../icon";
import * as styles from "./dropdown.module.scss";

export interface DropdownProps extends React.HTMLProps<HTMLSelectElement> {
  options: { label: string; value: string; disabled?: boolean | undefined }[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  invalid?: boolean | undefined;
}

export const Dropdown = ({
  id,
  disabled,
  required,
  invalid,
  options,
  value,
  onChange,
  ...otherProps
}: DropdownProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <select
        {...otherProps}
        className={classnames({
          [styles.base]: true,
          [styles.error]: !!invalid,
        })}
        value={value}
        disabled={disabled}
        required={required}
        aria-required={required}
        aria-invalid={invalid}
        onChange={!disabled ? onChange : undefined}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </select>
      <Icon
        className={classnames({
          [styles.icon]: true,
          [styles.disabled]: !!disabled,
        })}
        name="angleDown"
        size="s"
      />
    </div>
  );
};
