/**
 * Debouncing prevents extra activations or slow functions from triggering too often
 * @param func
 * @param wait
 */
export const debounce = (func: (...args: unknown[]) => void, wait: number) => {
  let timeout: any;
  return (...args: unknown[]): void => {
    clearTimeout(timeout);
    timeout = setTimeout(func, wait, ...args);
  };
};
