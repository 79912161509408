import * as React from "react";
import { classnames, isDefined } from "../../../utils";
import { Icon } from "../icon/icon";
import * as styles from "./button.module.scss";

export type ButtonKind = "primary" | "secondary" | "invisible";

export type ButtonSize = "s" | "m" | "l";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: React.ElementType;
  to?: string;
  kind?: ButtonKind;
  size?: ButtonSize;
  icon?: React.ReactNode;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  as,
  children,
  className,
  icon,
  kind = "secondary",
  size = "m",
  ...otherProps
}) => {
  const renderIcon = React.useCallback(() => {
    if (typeof icon === "string") {
      return <Icon name={icon} />;
    }
    if (typeof icon !== "undefined") {
      return icon;
    }
  }, [icon]);
  const Element = as ? as : "button";
  return (
    <Element
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.base]: true,
        [styles[kind as string]]: styles[kind as string] !== undefined,
        [styles[size as string]]: styles[size as string] !== undefined,
        [styles.iconOnly]: isDefined(icon) && !children,
        [styles.disabled]: !!otherProps.disabled,
      })}
    >
      {children && <span className={styles.label}>{children}</span>}
      {icon && <span className={styles.icon}>{renderIcon()}</span>}
    </Element>
  );
};
