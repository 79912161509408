import React from "react";
import { classnames } from "../../utils";
import * as styles from "./grid.module.scss";

interface GridRowProps {
  columns?: "1" | "2" | "3" | undefined;
}

interface GridColumnProps {
  span?: "2" | "3" | undefined;
}

export const Grid = ({ children, className }: React.PropsWithChildren<any>) => {
  return (
    <div
      className={classnames({
        [styles.container]: true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export const GridRow = ({
  columns,
  children,
  className = "",
  ...otherProps
}: React.PropsWithChildren<GridRowProps> & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...otherProps}
      className={classnames({
        [styles.row]: true,
        [styles.columns2]: columns === "2",
        [styles.columns3]: columns === "3",
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export const GridColumn = ({
  children,
  span,
  className = "",
  ...otherProps
}: React.PropsWithChildren<GridColumnProps> & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...otherProps}
      className={classnames({
        [styles.col]: true,
        [styles.span2]: span === "2",
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};
