import * as React from "react";
import { useNotifications } from "./hooks";
import { Notification } from "./notification";
import * as styles from "./notification-container.module.scss";

interface NotificationContainerProps extends React.HTMLProps<HTMLDivElement> {
  alignment?: "bottom" | "right";
}

export const NotificationContainer: React.FC<NotificationContainerProps> = ({ alignment, ...otherProps }) => {
  const { notifications } = useNotifications();
  const [isClient, setIsClient] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsClient(typeof window !== "undefined");
  }, []);

  return isClient ? (
    <div className={styles.base} {...otherProps}>
      {notifications.map((notification) => (
        <Notification key={notification.notificationId} {...notification} />
      ))}
    </div>
  ) : null;
};
