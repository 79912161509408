import { useState } from "react";
import { ApiResponse } from "../types";
import { IAssociationFormData, IAssociationFormHook, IAssociationFormResponse } from "./types";
import { patchAssociationDetails, patchAssociationDetailsMedia } from "./api";

export const useAssociationForm = (withFiles: boolean = false): IAssociationFormHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [associationFormResponse, setAssociationFormResponse] = useState<IAssociationFormResponse | null>(null);
  const [formErrors, setFormErrors] = useState<Record<string, string[]> | null>(null);

  const submitAssociationForm = async (
    id: number | string,
    data: IAssociationFormData
  ): Promise<void | ApiResponse<IAssociationFormResponse>> => {
    if (!data) {
      return;
    }
    setFormErrors(null);
    setLoading(true);

    let response: ApiResponse<IAssociationFormResponse>;
    try {
      response = withFiles ? await patchAssociationDetailsMedia(id, data) : await patchAssociationDetails(id, data);
      if (response.ok && response.data) {
        setAssociationFormResponse(response.data);
      } else if (response.error) {
        setFormErrors(response.error.errors || null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    return response;
  };

  return {
    loading,
    response: associationFormResponse,
    formErrors,
    submitAssociationForm,
  };
};
