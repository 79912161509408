import React from "react";
import { classnames } from "../../../utils";
import { TooltipPlacement } from "./types";
import { TooltipArrow } from "./tooltip-arrow";
import * as styles from "./tooltip.module.scss";

export interface TooltipProps {
  children: React.ReactNode;
  placement?: TooltipPlacement;
  text: React.ReactNode;
}

// TODO: implement portal version if facing issues with overflow hidden elements
export const Tooltip = ({ children, placement = "top", text }: TooltipProps) => {
  const triggerRef = React.useRef<HTMLDivElement | null>(null);
  const panelRef = React.useRef<HTMLDivElement | null>(null);
  const arrowRef = React.useRef<HTMLDivElement | null>(null);
  const timeoutRef = React.useRef<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const triggerElement = triggerRef.current;
    const panelElement = panelRef.current;
    const arrowElement = arrowRef.current;
    const spacing = 4;
    const pageMargin = 16;
    if (open && placement && triggerElement && panelElement) {
      const viewportWidth = document.documentElement.clientWidth;
      const viewportHeight = document.documentElement.clientHeight;
      const targetRect = triggerElement.getBoundingClientRect();

      let offsetX = 0;
      let offsetY = 0;
      if (/^top/.test(placement)) {
        offsetY = -1 * (panelElement.offsetHeight + triggerElement.offsetHeight + spacing);
        offsetX = 0.5 * (triggerElement.offsetWidth - panelElement.offsetWidth);
      } else if (/^bottom/.test(placement)) {
        offsetY = spacing;
        offsetX = 0.5 * (triggerElement.offsetWidth - panelElement.offsetWidth);
      } else if (/^left/.test(placement)) {
        offsetY = -0.5 * (triggerElement.offsetHeight + panelElement.offsetHeight);
        offsetX = -panelElement.offsetWidth - spacing;
      } else if (/^right/.test(placement)) {
        offsetY = -0.5 * (triggerElement.offsetHeight + panelElement.offsetHeight);
        offsetX = triggerElement.offsetWidth + spacing;
      }
      // Position correction if tooltip extends viewport
      const panelLeft = targetRect.x + offsetX;
      const panelTop = targetRect.y + targetRect.height + offsetY;
      const panelRight = panelLeft + panelElement.offsetWidth;
      const panelBottom = panelTop + panelElement.offsetHeight;

      let shiftX = 0;
      let shiftY = 0;

      if (/^(top|bottom)/.test(placement)) {
        if (panelLeft < pageMargin) {
          shiftX = pageMargin - panelLeft;
        } else if (panelRight > viewportWidth - pageMargin) {
          shiftX = viewportWidth - pageMargin - panelRight;
        }
      }
      if (/^(left|right)/.test(placement)) {
        if (panelTop < pageMargin) {
          shiftY = pageMargin - panelTop;
        } else if (panelBottom > viewportHeight - pageMargin) {
          shiftY = viewportHeight - pageMargin - panelBottom;
        }
      }
      if (arrowElement) {
        arrowElement.style.transform = `translate(${-shiftX}px, ${-shiftY}px)`;
      }

      panelElement.style.transform = `translate(${offsetX + shiftX}px, ${offsetY + shiftY}px)`;
    }
  }, [open, placement]);

  const handlePointerEnter = React.useCallback(() => {
    window.clearTimeout(timeoutRef.current);
    setOpen(true);
  }, []);

  const handlePointerLeave = React.useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      setOpen(false);
    }, 100);
  }, []);

  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.open]: open,
      })}
      ref={triggerRef}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {children}
      <div
        className={classnames({
          [styles.panel]: true,
        })}
        ref={panelRef}
      >
        <div className={styles.content}>{text}</div>
        <TooltipArrow placement={placement as TooltipPlacement} reference={arrowRef} />
      </div>
    </div>
  );
};
