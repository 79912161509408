import React, { FC, PropsWithChildren, useState } from "react";
import { DataEntryContext } from "./context";
import { IAssociationFormData } from "../../backend";

export const initialDataEntryFormData: IAssociationFormData = {
  category_org: "",
  name_org: "",
  email_org: "",
  gender: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  phone_number_org: "",
  website_org: "",
  street: "",
  zip_code: "",
  city: "",
  payment_purpose: "",
  payment_recipient: "",
  iban: "",
  store_ids: "",
  is_non_profit: false,
};

export const DataEntryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [formData, setFormData] = useState<IAssociationFormData>(initialDataEntryFormData);

  return <DataEntryContext.Provider value={{ formData, setFormData }}>{children}</DataEntryContext.Provider>;
};
