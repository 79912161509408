import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./spinner.module.scss";

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  fixed?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ fixed, ...otherProps }) => {
  return (
    <div
      className={classnames({
        [styles.spinner]: true,
        [styles.fixed]: !!fixed,
      })}
      {...otherProps}
    >
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          className={styles.ring}
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        />
        <path
          className={styles.segment}
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        />
      </svg>
    </div>
  );
};
