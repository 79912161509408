import { Dispatch, SetStateAction, createContext } from "react";
import { IAssociationFormData } from "../../backend";

export interface IDataEntryContext {
  formData: IAssociationFormData;
  setFormData: Dispatch<SetStateAction<IAssociationFormData>>;
}

export const DataEntryContext = createContext<IDataEntryContext>({
  formData: {} as IAssociationFormData,
  setFormData: () => {},
});
