import * as React from "react";
import { Box, Spacer } from "../dm";
import { StatusInfo } from "../status-info";

interface FormErrorsInfoProps {
  formErrors: Record<string, string | string[]> | null | undefined;
}

export const FormErrorsInfo = ({ formErrors }: FormErrorsInfoProps) => {
  return formErrors && Object.keys(formErrors).length > 0 ? (
    <Box>
      <StatusInfo>
        {Object.entries(formErrors).map(([key, value]) => {
          return <div key={key}>{Array.isArray(value) ? value.join(" ") : value}</div>;
        })}
      </StatusInfo>
      <Spacer size="s" />
    </Box>
  ) : null;
};
