const dateFormat = new Intl.DateTimeFormat(["de"], {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const timeFormat = new Intl.DateTimeFormat(["de"], {
  hour: "2-digit",
  minute: "2-digit",
});

const getDate = (value: string | Date): Date => {
  return typeof value === "string" ? new Date(value) : value;
};

export const formatDate = (value: string | Date) => {
  return dateFormat.format(getDate(value));
};

export const formatTime = (value: string | Date) => {
  return timeFormat.format(getDate(value));
};

export const formatDateTime = (value: string | Date) => {
  return `${formatDate(value)} ${formatTime(value)}`;
};
