import * as React from "react";
import { AssociationModel } from "../../backend";
import { AssociationDetailsHook } from "./types";
import { getAssociationDetails } from "./api";

export const useAssociationDetails = (associationId: string): AssociationDetailsHook => {
  const [association, setAssociation] = React.useState<AssociationModel | null>(null);
  const [pending, setPending] = React.useState<boolean>(!!associationId);

  const getAssociation = React.useCallback(async (): Promise<AssociationModel | null> => {
    if (!associationId) {
      setPending(false);
      return null;
    }
    setPending(true);
    const details = await getAssociationDetails(associationId);
    setAssociation(details);
    setPending(false);
    return details;
  }, [associationId]);

  React.useEffect(() => {
    getAssociation();
  }, [getAssociation]);

  return React.useMemo(
    () => ({
      association,
      getAssociation,
      pending,
    }),
    [association, getAssociation, pending]
  );
};
