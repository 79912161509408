import { UserModel, UserPermissionType, UserRoleName } from "../../backend/types";

export const getUserRoleName = (user: UserModel): UserRoleName => {
  try {
    return user.roles[0].name;
  } catch {
    return "";
  }
};

export const getUserPermissions = (user: UserModel): UserPermissionType[] => {
  try {
    return user.roles[0].permissions.map(({ name }) => name);
  } catch {
    return [];
  }
};

export const getHasPermission = (user: UserModel, permission: UserPermissionType): boolean => {
  return getUserPermissions(user).includes(permission);
};
