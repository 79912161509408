// extracted by mini-css-extract-plugin
export var aspect11 = "image-module--aspect-1-1--bafcb";
export var aspect169 = "image-module--aspect-16-9--1c0f4";
export var aspect34 = "image-module--aspect-3-4--dfdc1";
export var aspect43 = "image-module--aspect-4-3--d23e4";
export var aspect916 = "image-module--aspect-9-16--5ace2";
export var container = "image-module--container--a5d71";
export var img = "image-module--img--f4391";
export var lazy = "image-module--lazy--2aa8a";
export var loaded = "image-module--loaded--6a23c";
export var loading = "image-module--loading--26d12";
export var placeholder = "image-module--placeholder--90bf1";