import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./label.module.scss";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean | undefined;
  invalid?: boolean | undefined;
  required?: boolean | undefined;
}

export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  children,
  disabled = false,
  invalid = false,
  required = false,
  ...otherProps
}) => {
  return (
    <label
      {...otherProps}
      className={classnames({
        [styles.base]: true,
        [styles.disabled]: !!disabled,
        [styles.invalid]: !!invalid,
      })}
    >
      {children}
      {required && "*"}
    </label>
  );
};
