import * as React from "react";
import { I18nProvider } from "../i18n";
import { AssociationProvider, NotificationProvider, UserProvider } from "../contexts";
import { NotificationContainer } from "../components";

export const ContextWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <I18nProvider>
      <NotificationProvider>
        <UserProvider>
          <AssociationProvider>
            {children}
            <NotificationContainer />
          </AssociationProvider>
        </UserProvider>
      </NotificationProvider>
    </I18nProvider>
  );
};

export default ContextWrapper;
