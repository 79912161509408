import * as React from "react";
import { HeadFC } from "gatsby";
import { useSiteMetadata } from "../../contexts";

export const PageHead: HeadFC = () => {
  const { title, description, image } = useSiteMetadata();
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </>
  );
};
