import * as React from "react";
import { useNotifications } from "../../contexts";
import { PaginationModel, StoreStatisticsModel } from "../types";
import { getURLSearchParamNumeric } from "../utils";
import { StoreListHook } from "./types";
import { getGvList, getStoreList } from "./api";

export const useStoreList = (): StoreListHook => {
  const { addNotification } = useNotifications();
  const [gvs, setGvs] = React.useState<string[]>([]);
  const [stores, setStores] = React.useState<StoreStatisticsModel[]>([]);
  const [query, setQuery] = React.useState<Record<string, string>>({});
  const [page, setPage] = React.useState<number>(getURLSearchParamNumeric("page") || 1);
  const [pageSize] = React.useState<number | undefined>(getURLSearchParamNumeric("pageSize"));
  const [pagination, setPagination] = React.useState<PaginationModel | null>(null);
  const [pending, setPending] = React.useState<boolean>(false);

  const getGvs = React.useCallback(async (): Promise<string[]> => {
    let data: string[] = [];
    const res = await getGvList();
    if (res && res.ok && res.data) {
      data = res.data;
      setGvs(res.data);
    } else {
      if (res && res.error) {
        addNotification({
          type: "error",
          title: "",
          message: res.error.message,
        });
      }
      setGvs([]);
    }
    return data;
  }, [addNotification]);

  const getStores = React.useCallback(async (): Promise<StoreStatisticsModel[]> => {
    const searchQuery: Record<string, string | number> = {
      page,
      ...query,
    };
    if (pageSize) {
      searchQuery.pageSize = pageSize;
    }
    setPending(true);

    let data: StoreStatisticsModel[] = [];
    const res = await getStoreList(searchQuery);
    if (res && res.ok && res.data) {
      data = res.data.data;
      setStores(res.data.data);
      console.log(res.data.meta);
      setPagination(res.data.meta || null);
    } else {
      if (res && res.error) {
        addNotification({
          type: "error",
          title: "",
          message: res.error.message,
        });
      }
    }
    setPending(false);
    return data;
  }, [page, pageSize, query, addNotification]);

  React.useEffect(() => {
    getStores();
  }, [getStores]);

  const setQueryCallback = React.useCallback((query: Record<string, string>) => {
    setQuery(query);
    setPage(1);
  }, []);

  return React.useMemo(
    () => ({
      gvs,
      getGvs,
      stores,
      getStores,
      pagination,
      pending,
      setPage,
      query,
      setQuery: setQueryCallback,
    }),
    [gvs, getGvs, stores, getStores, pagination, pending, query, setQueryCallback]
  );
};
