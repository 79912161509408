import * as React from "react";
import { AuthTokenContext } from "./context";

export const AuthTokenProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [authToken, setAuthToken] = React.useState<string>("");

  React.useEffect(() => {
    setAuthToken(window.localStorage.getItem("authToken") || "");
  }, []);

  return <AuthTokenContext.Provider value={{ authToken, setAuthToken }}>{children}</AuthTokenContext.Provider>;
};
