export enum TooltipPlacementEnum {
  Top = "top",
  TopStart = "top-start",
  TopEnd = "top-end",
  Bottom = "bottom",
  BottomStart = "bottom-start",
  BottomEnd = "bottom-end",
  Left = "left",
  LeftStart = "left-start",
  LeftEnd = "left-end",
  Right = "left",
  RightStart = "right-start",
  RightEnd = "right-end",
}

export type TooltipPlacement =
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end"
  | string;
