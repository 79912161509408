import * as React from "react";
import { classnames } from "../../../utils/classnames";
import * as styles from "./checkbox.module.scss";
import { Icon } from "../icon/icon";

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean | undefined;
}

export const Checkbox = ({ invalid, disabled, checked, ...otherProps }: CheckboxProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <input
        {...otherProps}
        className={classnames({
          [styles.base]: true,
          [styles.error]: !!invalid,
        })}
        checked={checked}
        disabled={disabled}
        type="checkbox"
      />
      {checked && (
        <Icon
          className={classnames({
            [styles.icon]: true,
            [styles.disabled]: !!disabled,
          })}
          name="check"
        />
      )}
    </div>
  );
};
