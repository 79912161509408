import * as React from "react";
import i18next, { i18n } from "i18next";
import { I18nContext } from "./context";
import initI18n from "./initI18next";

export const I18nProvider = ({ children }: React.PropsWithChildren) => {
  const [i18n, setI18n] = React.useState<i18n>(i18next);

  React.useEffect(() => {
    initI18n().then(() => {
      setI18n({
        ...i18next,
      });
    });
  }, []);

  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
};
