import React from "react";
import { classnames } from "../../../utils";
import * as styles from "./textarea.module.scss";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  invalid?: boolean | undefined;
}

export const Textarea = ({ className, disabled, invalid, required, ...otherProps }: TextareaProps) => {
  return (
    <textarea
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.base]: true,
        [styles.error]: !!invalid,
      })}
      aria-required={required}
      aria-invalid={invalid}
      disabled={disabled}
    />
  );
};
