import * as React from "react";
import { AssociationListPaginationResponse, AssociationListResponse, AssociationModel } from "../../backend";
import { useAssociationContext } from "../../contexts";
import { getAssociationList } from "./api";
import { AssociationListHook, AssociationListPaginationMeta } from "./types";
import { categoryToApprovalState } from "./utils";
import { getURLSearchParamNumeric } from "../utils";

export const useAssociationList = (category: string): AssociationListHook => {
  const [associations, setAssociations] = React.useState<AssociationModel[]>([]);
  const [page, setPage] = React.useState<number>(getURLSearchParamNumeric("page") || 1);
  const [pageSize] = React.useState<number | undefined>(getURLSearchParamNumeric("pageSize"));
  const [pagination, setPagination] = React.useState<AssociationListPaginationMeta | null>(null);
  const [pending, setPending] = React.useState<boolean>(false);
  const { setStatistics } = useAssociationContext();

  const getAssociations = React.useCallback(async (): Promise<AssociationModel[]> => {
    const query: Record<string, string | number> = {
      page,
    };
    if (pageSize) {
      query.pageSize = pageSize;
    }
    const approvalState = categoryToApprovalState(category);
    if (approvalState) {
      query.approval_state = approvalState;
    }
    setPending(true);
    try {
      const res = (await getAssociationList(query)) as AssociationListResponse & AssociationListPaginationResponse;
      if (res) {
        if (Array.isArray(res.data)) {
          setAssociations(res.data);
        } else {
          setAssociations([]);
        }
        if (res.meta) {
          setPagination(res.meta);
          setStatistics(res.meta.statistics);
        } else if (res.statistics) {
          setStatistics(res.statistics);
        } else {
          setPagination(null);
        }
      }
    } catch (e) {
      console.log(e);
      setAssociations([]);
      setStatistics(null);
    }
    setPending(false);
  }, [category, page, pageSize, setStatistics]);

  React.useEffect(() => {
    getAssociations();
  }, [getAssociations]);

  return React.useMemo(
    () => ({
      associations,
      getAssociations,
      pagination,
      pending,
      setPage,
    }),
    [associations, getAssociations, pagination, pending]
  );
};
