import { useCallback, useContext } from "react";
import { UserPermissionType } from "../../backend";
import { UserContextType } from "./interface";
import { UserContext } from "./context";

export const useUser = (): UserContextType => useContext(UserContext);

export const useGetPermission = (): ((value: UserPermissionType) => boolean) => {
  const { permissions } = useUser();

  return useCallback(
    (action: UserPermissionType): boolean => {
      return permissions.includes(action);
    },
    [permissions]
  );
};
