import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./text.module.scss";

export type TextWeight = "bold" | "normal";
export type TextKind = "error" | "default" | "pressKit";
export type TextSize = "small" | "medium";

export interface TextProps extends Omit<React.ComponentProps<"p">, "as"> {
  as?: React.ElementType;
  children: React.ReactNode;
  weight?: TextWeight;
  kind?: TextKind;
  size?: TextSize;
  centered?: boolean;
}

export const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  as: Component = "p",
  centered,
  children,
  className,
  kind = "default",
  weight = "normal",
  size = "medium",
  ...otherProps
}: TextProps) => {
  return (
    <Component
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.base]: true,
        [styles[weight]]: styles[weight] !== undefined,
        [styles[kind]]: styles[kind] !== undefined,
        [styles[size]]: styles[size] !== undefined,
        [styles.centered]: !!centered,
      })}
    >
      {children}
    </Component>
  );
};
