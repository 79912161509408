import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./panel.module.scss";

interface PanelProps extends React.HTMLProps<HTMLDivElement> {
  as?: string;
  reference?: string | React.Ref<unknown>;
  children: React.ReactNode;
  centered?: boolean;
  centeredLeft?: boolean;
  noShadow?: boolean;
}

export const Panel: React.FC<PanelProps> = ({
  as: Component = "div",
  reference,
  centered,
  children,
  noShadow,
  centeredLeft,
  ...otherProps
}) => {
  const Element = (Component || "div") as React.ElementType;
  return (
    <Element
      className={classnames({
        [styles.base]: true,
        [styles.centered]: !!centered,
        [styles.centeredLeft]: !!centeredLeft,
        [styles.noShadow]: !!noShadow,
      })}
      ref={reference}
      {...otherProps}
    >
      {children}
    </Element>
  );
};
