import React, { useCallback, useRef, useState } from "react";
import { NotificationBase, NotificationItemType } from "./types";
import { NotificationContext } from "./context";

let notificationIncrementId: number = 0;

export const NotificationProvider = ({ children }: React.PropsWithChildren) => {
  const [notifications, setNotifications] = useState<NotificationItemType[]>([]);
  const notificationsRef = useRef<NotificationItemType[]>([]);
  notificationsRef.current = notifications;

  const removeNotification = useCallback((notificationId: string) => {
    const removedNotification = notificationsRef.current.find((item) => item.notificationId === notificationId);
    if (!removedNotification) {
      return;
    }
    setNotifications(notificationsRef.current.filter((item) => item.notificationId !== notificationId));
  }, []);

  const addNotification = useCallback(
    (notification: NotificationBase) => {
      const item: NotificationItemType = {
        lifetime: 10000,
        ...notification,
        notificationId: `notification-${++notificationIncrementId}`,
      };
      setNotifications([item, ...notificationsRef.current]);
      window.setTimeout(() => {
        removeNotification(item.notificationId);
      }, item.lifetime);
    },
    [removeNotification]
  );

  return (
    <NotificationContext.Provider
      value={{
        addNotification,
        removeNotification,
        notifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
