import { AssociationListResponse } from "./types";
import { getApiBaseUrl } from "../utils";
import { api } from "../api";

const API_ENDPOINT = `${getApiBaseUrl()}/api/v1/association`;

export const getAssociationList = async (
  query?: Record<string, string | number>
): Promise<AssociationListResponse | null> => {
  let data: AssociationListResponse | null = null;
  let url = API_ENDPOINT;
  const params = new URLSearchParams();
  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      params.set(key, `${value}`);
    });
    url = `${API_ENDPOINT}?${params.toString()}`;
  }
  try {
    const res = await api<AssociationListResponse | null>(url, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (res.ok) {
      data = res.data || null;
    }
  } catch (e) {
    console.log(e);
  }
  return data;
};
