import React from "react";
import { classnames, isNonEmptyString } from "../../../utils";
import IconRegistry from "./icon-registry";
import * as styles from "./icon.module.scss";

export type IconName = keyof typeof IconRegistry;

export type IconSize = "s" | "m" | "l" | "xl" | "xxl" | "xxxl" | "socials";

export interface IconProps extends Omit<React.SVGAttributes<HTMLOrSVGElement>, "name"> {
  name: IconName;
  size?: IconSize | undefined;
}

export const Icon = ({ className, name, size, ...otherProps }: IconProps) => {
  const Component = IconRegistry[name];
  return !Component ? null : (
    <Component
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.base]: true,
        [styles[size as string]]: isNonEmptyString(size) && styles[size as string] !== undefined,
      })}
    />
  );
};
