// extracted by mini-css-extract-plugin
export var base = "notification-module--base--4b3af";
export var close = "notification-module--close--31976";
export var content = "notification-module--content--304dc";
export var error = "notification-module--error--a2dd5";
export var icon = "notification-module--icon--742ee";
export var info = "notification-module--info--0214d";
export var inner = "notification-module--inner--73a5d";
export var message = "notification-module--message--fc1be";
export var success = "notification-module--success--116ae";
export var title = "notification-module--title--7b1c7";
export var toastAppear = "notification-module--toast-appear--2c23a";
export var warning = "notification-module--warning--0ed8b";