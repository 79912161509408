// extracted by mini-css-extract-plugin
export var base = "spacer-module--base--59581";
export var l = "spacer-module--l--2d071";
export var m = "spacer-module--m--781b1";
export var s = "spacer-module--s--028d7";
export var xl = "spacer-module--xl--dab21";
export var xs = "spacer-module--xs--7305d";
export var xxl = "spacer-module--xxl--f6af5";
export var xxs = "spacer-module--xxs--500b1";
export var xxxl = "spacer-module--xxxl--9ddea";
export var xxxs = "spacer-module--xxxs--e4542";