import React from "react";
import { UserContext } from "./context";
import { UserContextType } from "./interface";
import { UserModel, getUser as requestUser } from "../../backend";
import { getUserPermissions, getUserRoleName } from "./utils";

export const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<UserModel | null>(null);

  const getUser = React.useCallback(async (): Promise<UserModel | null> => {
    const res = await requestUser();
    if (res.ok && res.data) {
      setUser(res.data);
      setLoggedIn(true);
      return res.data;
    } else {
      setUser(null);
      setLoggedIn(false);
      return null;
    }
  }, []);

  React.useEffect(() => {
    if (!loggedIn) {
      setUser(null);
    }
  }, [loggedIn]);

  React.useEffect(() => {
    if ((!loggedIn && pending) || loggedIn) {
      getUser().then(() => setPending(false));
    }
  }, [getUser, loggedIn, pending]);

  const value = React.useMemo<UserContextType>(() => {
    const permissions = user ? getUserPermissions(user) : [];
    const role = user ? getUserRoleName(user) : "";
    return {
      getUser,
      loggedIn,
      pending,
      permissions,
      role,
      setLoggedIn,
      user,
    };
  }, [loggedIn, user, getUser, pending]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
