import { useContext, useMemo } from "react";
import type { i18n } from "i18next";
import { I18nContext } from "./context";

interface I18nTranslation extends Omit<i18n, "t"> {
  t: (key: string, ...args) => string;
}

export const useI18n = (): i18n => useContext(I18nContext);

export const useTranslation = (): I18nTranslation => {
  const i18n = useContext(I18nContext);
  const { t } = i18n;

  return useMemo<I18nTranslation>(() => {
    const tFunction = (key: string, ...args): string => t(key as any, ...args) as string;
    return {
      ...i18n,
      t: tFunction,
    };
  }, [i18n, t]);
};
