import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./input.module.scss";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: "text" | "date" | "email" | "password" | "number" | "tel";
  invalid?: boolean | undefined;
}

export const Input: React.FC<InputProps> = ({ type = "text", invalid = false, ...otherProps }) => {
  return (
    <input
      {...otherProps}
      type={type}
      className={classnames({
        [styles.base]: true,
        [styles.invalid]: !!invalid,
      })}
    />
  );
};
