import { AssociationModel, AssociationCommentModel, StoreStatisticsModel } from "../../backend/types";

export const mockStores: StoreStatisticsModel[] = [
  {
    store_id: "de-12345",
    approved_associations_count: 1,
    approved_associations_necessary_count: 2,
    invited_associations_count: 1,
    invited_associations_necessary_count: 2,
  },
  {
    store_id: "de-54321",
    approved_associations_count: 0,
    approved_associations_necessary_count: 2,
    invited_associations_count: 1,
    invited_associations_necessary_count: 2,
  },
  {
    store_id: "de-24680",
    approved_associations_count: 2,
    approved_associations_necessary_count: 2,
    invited_associations_count: 1,
    invited_associations_necessary_count: 2,
  },
  {
    store_id: "de-24680",
    approved_associations_count: 2,
    approved_associations_necessary_count: 2,
    invited_associations_count: 1,
    invited_associations_necessary_count: 2,
  },
  {
    store_id: "de-13579",
    approved_associations_count: 1,
    approved_associations_necessary_count: 2,
    invited_associations_count: 1,
    invited_associations_necessary_count: 2,
  },
] as StoreStatisticsModel[];

const mockComments: AssociationCommentModel[] = [
  {
    id: 1,
    user_name: "dm Markt (de-xxxx)",
    created_at: "2024-04-02T18:35:04.381Z",
    updated_at: "2024-04-02T18:35:04.381Z",
    message:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
  },
  {
    id: 2,
    user_name: "QS (Arthen)",
    created_at: "2024-04-03T11:14:53.381Z",
    updated_at: "2024-04-03T11:14:53.381Z",
    message:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
  },
  {
    id: 3,
    user_name: "dm Markt (de-xxxx)",
    created_at: "2024-04-04T12:27:53.381Z",
    updated_at: "2024-04-04T12:27:53.381Z",
    message:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  },
] as AssociationCommentModel[];

export const mockData: AssociationModel[] = [
  {
    id: 1,
    approval_state: "approved",
    name_org: "Approved Association",
    gender: "Herr",
    first_name: "John",
    last_name: "Doe",
    email: "john@example.com",
    phone_number: "123-456-7890",
    store_ids: "de-12345",
    media_logo:
      "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?w=826&t=st=1708704876~exp=1708705476~hmac=c69b4bab5ee1b76643d5583eb10128c6a9bfe5105db2cf9a11acb874b8f2ea03",
    created_at: "2024-03-16",
    comments: mockComments,
  },
  {
    id: 2,
    approval_state: "rejected",
    name_org: "Rejected Association",
    gender: "Frau",
    first_name: "Jane",
    last_name: "Doe",
    email: "jane@example.com",
    phone_number: "987-654-3210",
    store_ids: "de-54321",
    media_logo:
      "https://img.freepik.com/free-vector/geometric-triangle-logo_1043-89.jpg?t=st=1708704909~exp=1708708509~hmac=f981d581a6c378141e579fc853716497acf4ef0ace09c3678b61d3ad9d43c513&w=826",
    created_at: "2024-03-15",
    comments: mockComments,
  },
  {
    id: 3,
    approval_state: "changes-requested",
    name_org: "Changes Requested Association",
    gender: "Frau",
    first_name: "Alice",
    last_name: "Smith",
    email: "alice@example.com",
    phone_number: "555-555-5555",
    store_ids: "de-67890",
    media_logo:
      "https://img.freepik.com/free-vector/fox-icon-silhouette-illustration_361591-2507.jpg?t=st=1711375388~exp=1711378988~hmac=ed6c8cffd4ce607db7bd94e076dd5d134519251ebcef2022e7fa147e923363ca&w=826",
    created_at: "2024-03-14",
    comments: mockComments,
  },
  {
    id: 4,
    approval_state: "data-requested",
    name_org: "Data Requested Association",
    gender: "Herr",
    first_name: "Bob",
    last_name: "Johnson",
    email: "bob@example.com",
    phone_number: "777-777-7777",
    store_ids: "de-13579",
    media_logo:
      "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?w=826&t=st=1708704876~exp=1708705476~hmac=c69b4bab5ee1b76643d5583eb10128c6a9bfe5105db2cf9a11acb874b8f2ea03",
    created_at: "2024-03-13",
  },
  {
    id: 5,
    approval_state: "review-qa",
    name_org: "Review QA Association",
    gender: "Frau",
    first_name: "Emily",
    last_name: "Williams",
    email: "emily@example.com",
    phone_number: "444-444-4444",
    store_ids: "de-24680",
    media_logo:
      "https://img.freepik.com/free-vector/geometric-triangle-logo_1043-89.jpg?t=st=1708704909~exp=1708708509~hmac=f981d581a6c378141e579fc853716497acf4ef0ace09c3678b61d3ad9d43c513&w=826",
    created_at: "2024-03-12",
    comments: mockComments,
  },
  {
    id: 6,
    approval_state: "review-store",
    name_org: "Review Store Association",
    gender: "Frau",
    first_name: "Emily",
    last_name: "Williams",
    email: "emily@example.com",
    phone_number: "555-321-1234",
    store_ids: "de-24680",
    media_logo:
      "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?w=826&t=st=1708704876~exp=1708705476~hmac=c69b4bab5ee1b76643d5583eb10128c6a9bfe5105db2cf9a11acb874b8f2ea03",
    created_at: "2024-03-12",
    comments: mockComments,
  },
] as AssociationModel[];
