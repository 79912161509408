import React from "react";
import { useUid } from "../../../utils";
import { Box, Spacer } from "../layout";
import { Label } from "../label";
import { Text } from "../text";
import { Dropdown, DropdownProps } from "./dropdown";
import * as styles from "./dropdown-field.module.scss";

export interface DropdownFieldProps extends DropdownProps {
  label?: string;
  hint?: string;
  errorMessage?: React.ReactNode;
  isFloatingLabel?: boolean;
}

export const DropdownField = ({
  id,
  options,
  onChange,
  value,
  disabled,
  invalid,
  required,
  label,
  style,
  errorMessage,
  hint,
  isFloatingLabel = false,
  ...otherProps
}: DropdownFieldProps) => {
  const uid = useUid();
  const controlId = `dropdown-${uid}`;
  const errorId = `dropdown-error-${uid}`;

  const dropdownElement = (
    <Dropdown
      {...otherProps}
      id={controlId}
      value={value}
      options={options}
      disabled={disabled}
      invalid={invalid}
      required={required}
      onChange={onChange}
      aria-describedby={errorMessage && invalid ? errorId : undefined}
    />
  );

  const labelElement = (
    <Label
      htmlFor={controlId}
      disabled={disabled}
      invalid={invalid}
      required={required}
      className={isFloatingLabel ? styles.floatingLabel : styles.label}
    >
      {label}
    </Label>
  );
  return (
    <Box className={isFloatingLabel ? styles.box : styles.container} id={id}>
      {isFloatingLabel ? (
        <div>
          {dropdownElement}
          {labelElement}
          {hint && (
            <>
              <Spacer size="xxxs" />
              <Text className={styles.hint}>{hint}</Text>
            </>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          {label && labelElement}
          {hint && <Text className={styles.hint}>{hint}</Text>}
          {dropdownElement}
        </div>
      )}

      {errorMessage && invalid && (
        <div className={styles.wrapper}>
          <Spacer size="xxxs" />
          <Text id={errorId} kind="error">
            {errorMessage}
          </Text>
        </div>
      )}
    </Box>
  );
};
