export const isDefined = (value: unknown): boolean => {
  return typeof value !== "undefined" && value !== null;
};

export const isString = (value: unknown): boolean => {
  return typeof value === "string";
};

export const isNonEmptyString = (value: unknown): boolean => {
  return typeof value === "string" && value !== "";
};
