import * as React from "react";
import { classnames } from "../../../utils";
import { Icon } from "../icon";
import * as styles from "./video.module.scss";

interface VideoProps extends React.ComponentProps<HTMLVideoElement> {
  aspectRatio: "16/9" | "4/3" | "9/16" | "3/4";
}

export const Video: React.FC<VideoProps> = ({ aspectRatio, className, controls, ...otherProps }) => {
  const [shouldPlay, setShouldPlay] = React.useState<boolean>(false);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  const handleClickPlay = React.useCallback(async () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const paused = video.paused;
    if (paused) {
      await video.play();
      document.querySelectorAll<HTMLVideoElement>("video").forEach((videoElement) => {
        if (videoElement !== videoRef.current && !videoElement.paused) {
          videoElement.pause();
        }
      });
    } else {
      video.pause();
    }
    setShouldPlay(!video.paused);
  }, []);

  const handleVideoEvent = React.useCallback(() => {
    window.setTimeout(() => {
      const video = videoRef.current;
      if (!video) {
        return;
      }
      setShouldPlay(!video.paused && !video.ended);
    }, 200);
  }, []);

  return (
    <div className={styles.container}>
      <video
        {...otherProps}
        ref={videoRef}
        className={classnames({
          [`${className}`]: !!className,
          [styles.video]: true,
          [styles.aspect169]: aspectRatio === "16/9",
          [styles.aspect916]: aspectRatio === "9/16",
          [styles.aspect43]: aspectRatio === "4/3",
          [styles.aspect34]: aspectRatio === "3/4",
        })}
        controls={shouldPlay ? controls : false}
        onPlay={handleVideoEvent}
        onPause={handleVideoEvent}
        onEnded={handleVideoEvent}
      />
      <button
        className={classnames({
          [styles.play]: true,
          [styles.inactive]: shouldPlay,
        })}
        type="button"
        aria-label={shouldPlay ? "Video anhalten" : "Video starten"}
        onClick={handleClickPlay}
      >
        <Icon name="play" size="xxxl" />
      </button>
    </div>
  );
};
