import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "../../i18n";
import { Box, Heading, Spacer, Text, Button, Cell } from "../../components";
import * as styles from "./page-content-header.module.scss";

interface PageContentHeaderProps {
  title: React.ReactNode;
  description: React.ReactNode;
  button?: React.ReactNode;
  linkBack?: string;
}

export const PageContentHeader: React.FC<PageContentHeaderProps> = ({
  title,
  description,
  button,
  linkBack = false,
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Heading kind="secondary" as="h1">
        {title}
      </Heading>
      <Spacer size="s" />
      <Cell className={styles.row}>
        <Text className={styles.content}>{description}</Text>
        {(button || linkBack) && (
          <div className={styles.buttons}>
            {button}
            {linkBack && !button && (
              <Button kind="secondary" as={Link} to={linkBack}>
                {t("page.pageContentHeader.goBack.button")}
              </Button>
            )}
          </div>
        )}
      </Cell>
    </Box>
  );
};
