import React from "react";

export enum NotificationTypesEnum {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export type NotificationType = "info" | "success" | "warning" | "error";

export interface NotificationBase {
  notificationId?: string;
  lifetime?: number;
  message: React.ReactNode;
  title?: string;
  type: NotificationType;
}

export interface NotificationItemType extends NotificationBase {
  notificationId: string;
  lifetime?: number;
}

export interface NotificationContextType {
  notifications: NotificationItemType[];
  addNotification: (notification: NotificationBase) => void;
  removeNotification: (notificationId: string) => void;
}
