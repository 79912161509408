import React from "react";
import { useUid } from "../../../utils";
import { Box, Spacer } from "../layout";
import { Label } from "../label";
import { Text } from "../text";
import { Textarea, TextareaProps } from "./textarea";

export interface TextareaFieldProps extends TextareaProps {
  label: string;
  hint?: string;
  errorMessage?: React.ReactNode;
}

export const TextareaField = ({
  disabled,
  id,
  invalid,
  required,
  label,
  errorMessage,
  hint,
  ...otherProps
}: TextareaFieldProps) => {
  const uid = useUid();
  const controlId = `textarea-${uid}`;
  const errorMsgId = `textarea-error-${uid}`;
  return (
    <Box id={id}>
      <Label htmlFor={controlId} disabled={disabled} invalid={invalid} required={required}>
        {label}
      </Label>
      {hint && (
        <>
          <Text size="small">{hint}</Text>
          <Spacer size="xxxs" />
        </>
      )}
      <Textarea
        {...otherProps}
        id={controlId}
        required={required}
        disabled={disabled}
        invalid={invalid}
        aria-describedby={errorMessage && invalid ? errorMsgId : undefined}
      />
      {errorMessage && invalid && (
        <>
          <Spacer size="xxxs" />
          <Text id={errorMsgId} kind="error" size="small">
            {errorMessage || ""}
          </Text>
        </>
      )}
    </Box>
  );
};
