import React from "react";
import { useUid } from "../../../utils";
import { Box, Spacer } from "../layout";
import { Label } from "../label";
import { Text } from "../text";
import { Input, InputProps } from "./input";
import { Tooltip } from "../tooltip";
import { Icon } from "../icon";
import * as styles from "./input.module.scss";

export interface InputFieldProps extends InputProps {
  label: string;
  hint?: string;
  errorMessage?: React.ReactNode;
  tooltipText?: React.ReactNode;
  icon?: string;
}

export const InputField = ({
  disabled,
  id,
  invalid,
  required,
  label,
  errorMessage,
  hint,
  tooltipText,
  icon = "info",
  ...otherProps
}: InputFieldProps) => {
  const uid = useUid();
  const controlId = `input-${uid}`;
  const errorMsgId = `input-error-${uid}`;
  return (
    <Box id={id}>
      <Label htmlFor={controlId} disabled={disabled} invalid={invalid} required={required}>
        {label}
      </Label>
      {hint && (
        <>
          <Text size="small">{hint}</Text>
          <Spacer size="xxxs" />
        </>
      )}
      <div className={styles.field}>
        <Input
          {...otherProps}
          id={controlId}
          required={required}
          disabled={disabled}
          invalid={invalid}
          aria-describedby={errorMessage && invalid ? errorMsgId : undefined}
        />
        {!!tooltipText && (
          <div className={styles.tooltip}>
            <Tooltip placement="top" text={tooltipText}>
              <Icon name="info" size="m" />
            </Tooltip>
          </div>
        )}
      </div>
      {errorMessage && invalid && (
        <>
          <Spacer size="xxxs" />
          <Text id={errorMsgId} kind="error" size="small">
            {errorMessage || ""}
          </Text>
        </>
      )}
    </Box>
  );
};
