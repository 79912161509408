import * as React from "react";
import { classnames, isNonEmptyString } from "../../utils";
import { Icon } from "../dm/icon/icon";
import type { NotificationBase } from "./interface";
import { useNotifications } from "./hooks";
import { resolveIcon } from "./utils";
import * as styles from "./notification.module.scss";

interface NotificationProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "message" | "title" | "type">,
    NotificationBase {
  icon?: string;
}

export const Notification: React.FC<NotificationProps> = ({
  notificationId,
  icon,
  message,
  title,
  type,
  ...otherProps
}) => {
  const { removeNotification } = useNotifications();
  const iconName = isNonEmptyString(icon) ? (icon as string) : resolveIcon(type as string);

  const handleRemove = React.useCallback(() => {
    if (notificationId) {
      removeNotification(notificationId);
    }
  }, [removeNotification, notificationId]);

  return (
    <div
      className={classnames({
        [styles.base]: true,
        [styles[type as string]]: styles[type as string] !== undefined,
      })}
      {...otherProps}
    >
      <div className={styles.inner}>
        <div className={styles.icon}>
          <Icon name={iconName} />
        </div>
        <div className={styles.content}>
          {title && <div className={styles.title}>{title}</div>}
          <div className={styles.message}>{message}</div>
        </div>
        <button className={styles.close} type="button" onClick={handleRemove}>
          <Icon name="crossCircle" />
        </button>
      </div>
    </div>
  );
};
