// extracted by mini-css-extract-plugin
export var base = "file-upload-module--base--cdc51";
export var disabled = "file-upload-module--disabled--8cb0f";
export var field = "file-upload-module--field--c2377";
export var fileUploadContainer = "file-upload-module--fileUploadContainer--c192e";
export var fileUploadHiddenInput = "file-upload-module--fileUploadHiddenInput--7076c";
export var fileUploadIcon = "file-upload-module--fileUploadIcon--0292f";
export var fileUploadInput = "file-upload-module--fileUploadInput--63bb5";
export var inputWrapper = "file-upload-module--inputWrapper--b1245";
export var invalid = "file-upload-module--invalid--711fc";
export var tooltip = "file-upload-module--tooltip--44e6d";