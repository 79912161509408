import * as React from "react";
import { navigate } from "gatsby";
import { useUser } from "../../contexts";
import { Text, View } from "../dm";
import { Page } from "./page";

export const ProtectedPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loggedIn, pending } = useUser();

  React.useEffect(() => {
    if (!loggedIn && !pending) {
      navigate("/user/login");
    }
  }, [loggedIn, pending]);

  return (
    <Page>
      {!loggedIn && pending && (
        <View>
          <Text>Anmeldung wird geprüft …</Text>
        </View>
      )}
      {loggedIn && children}
    </Page>
  );
};
