import { createContext } from "react";
import { UserContextType } from "./interface";

export const UserContext = createContext<UserContextType>({
  getUser: async () => null,
  loggedIn: false,
  pending: false,
  permissions: [],
  setLoggedIn: () => {},
  user: null,
  role: "",
});
