export const validateIBAN = (str: string) => {
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/;
  return ibanRegex.test(str);
};

export const validateEmail = (str: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(str);
};

export const openMediaPreview = (file: File | null) => {
  if (file) {
    window.open(URL.createObjectURL(file));
  }
};
