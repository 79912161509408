import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./view.module.scss";

export type ViewAlign = "center" | "left" | "right";

export type ViewSize = "s" | "m" | "l";

export interface ViewProps extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  align?: ViewAlign;
  size?: ViewSize;
  noPadding?: boolean;
}

export const View: React.FC<React.PropsWithChildren<ViewProps>> = ({
  children,
  align = "center",
  size = "l",
  noPadding = false,
  className,
  ...otherProps
}) => {
  return (
    <div
      className={classnames({
        [styles.base]: true,
        [styles[align]]: styles[align] !== undefined,
        [styles[size]]: styles[size] !== undefined,
        [styles.padding]: !noPadding,
        [className]: !!className,
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
};
