import axios from "axios";
import { IAssociationFormResponse, IAssociationFormData } from "./types";
import { ApiResponse, AssociationModel } from "../types";
import { getApiBaseUrl } from "../utils";
import { api } from "../api";

const API_ENDPOINT = `${getApiBaseUrl()}/api/v1/association`;

export const getAssociationDetails = async (associationId: string | number): Promise<AssociationModel | null> => {
  let data: AssociationModel | null = null;
  try {
    const res = await api<AssociationModel | null>(`${API_ENDPOINT}/${associationId}`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (res.ok) {
      data = res.data || null;
    }
  } catch (e) {
    console.log(e);
  }
  return data;
};

export const patchAssociationDetails = async (
  id: number | string,
  data: IAssociationFormData
): Promise<ApiResponse<IAssociationFormResponse>> => {
  return await api<AssociationModel>(`${API_ENDPOINT}/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      ...data,
      _method: "patch",
    }),
  });
};

export const patchAssociationDetailsMedia = async (
  id: number | string,
  data: IAssociationFormData
): Promise<ApiResponse<IAssociationFormResponse>> => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value as string | File);
  });
  formData.append("_method", "patch");

  const options = {
    url: `${API_ENDPOINT}/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    withCredentials: true,
    data: formData,
  };

  let response: ApiResponse<IAssociationFormResponse>;
  try {
    const res = await axios.request(options);
    if (res && res.data) {
      response = {
        ...(res as Response),
        ok: true,
        data: res.data as IAssociationFormResponse,
        error: undefined,
      };
    }
  } catch (err) {
    response = {
      ...err.response,
      ok: false,
      data: undefined,
      error: err.response?.data,
    };
  }
  return response;
};
