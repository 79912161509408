import React, { useEffect, useState, useRef } from "react";
import { classnames, debounce, useUid } from "../../utils";
import * as styles from "./tabs.module.scss";

interface TabsProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  initialTab?: number;
  items: TabType[];
}

export type TabType = {
  headline: React.ReactNode;
  content: React.ReactNode;
  id: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

interface TabsIndicatorData {
  left: number;
  width: number;
}

export const Tabs: React.FC<TabsProps> = ({ items, className, initialTab = 0, ...otherProps }) => {
  const [activeTab, setActiveTab] = useState(initialTab || 0);
  const [indicatorPosition, setIndicatorPosition] = useState<TabsIndicatorData | null>(null);
  const tablistRef = useRef<HTMLDivElement | null>(null);
  const uid = useUid();

  useEffect(() => {
    const handleResize = debounce(() => {
      const activeTabElement = tablistRef.current?.childNodes[activeTab] as HTMLButtonElement;
      if (activeTabElement) {
        setIndicatorPosition({
          left: activeTabElement.offsetLeft,
          width: activeTabElement.offsetWidth - 2,
        });
      } else {
        setIndicatorPosition({
          left: 0,
          width: 0,
        });
      }
    }, 20);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeTab]);

  return (
    <div
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.tabs]: true,
      })}
    >
      <div role="tablist" ref={tablistRef} className={styles.tablist}>
        {items.map((item, index) => {
          const tabClass = classnames({
            [styles.tab]: true,
            [styles.active]: index === activeTab,
          });
          const tabId = `tab-${item.id}-${uid}`;
          const tabPanelId = `tab-panel-${item.id}-${uid}`;

          return (
            <button
              key={tabId}
              id={tabId}
              aria-controls={tabPanelId}
              aria-selected={index === activeTab}
              className={tabClass}
              role="tab"
              type="button"
              onClick={(e) => {
                setActiveTab(index);
                item.onClick && item.onClick(e);
              }}
              disabled={item.disabled}
            >
              {item.headline}
            </button>
          );
        })}
        {indicatorPosition && (
          <div className={styles.indicator} style={{ left: indicatorPosition.left, width: indicatorPosition.width }} />
        )}
      </div>
      {items.map((item, index) => {
        const panelClass = classnames({
          [styles.panel]: true,
          [styles.active]: index === activeTab,
        });
        const tabId = `tab-${item.id}-${uid}`;
        const tabPanelId = `tab-panel-${item.id}-${uid}`;

        return (
          <div
            key={tabPanelId}
            id={tabPanelId}
            role="tabpanel"
            aria-hidden={index !== activeTab}
            aria-labelledby={tabId}
            className={panelClass}
          >
            {index === activeTab && item.content}
          </div>
        );
      })}
    </div>
  );
};
