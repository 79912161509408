import * as React from "react";
import { AssociationCreationLimits, CheckLimitHook } from "./types";
import { getCheckLimit } from "./api";

export const useCheckLimit = (): CheckLimitHook => {
  const [limits, setLimits] = React.useState<AssociationCreationLimits[]>([]);
  const [pending, setPending] = React.useState<boolean>(true);

  const getLimits = React.useCallback(async (): Promise<AssociationCreationLimits[]> => {
    const res = await getCheckLimit();
    let data: AssociationCreationLimits[] = [];
    if (res && res.ok && res.data) {
      data = res.data;
      setLimits(res.data);
    }
    setPending(false);
    return data;
  }, []);

  React.useEffect(() => {
    getLimits();
  }, [getLimits]);

  return React.useMemo<CheckLimitHook>(() => {
    const free: number = limits.map<number>((limit) => limit.free).reduce((total, free) => total + free, 0);
    return {
      free,
      limits,
      pending,
    };
  }, [limits, pending]);
};
