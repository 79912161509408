import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./box.module.scss";

export interface BoxProps extends React.HTMLProps<HTMLDivElement> {
  as?: string;
  reference?: React.Ref<React.ElementType>;
}

export const Box = ({ as: Component = "div", children, className, reference, ...otherProps }: BoxProps) => {
  const Element = (Component || "div") as React.ElementType;
  return (
    <Element
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.box]: true,
      })}
      ref={reference}
    >
      {children}
    </Element>
  );
};
