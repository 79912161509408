import { getApiBaseUrl } from "../utils";
import { ApiResponse } from "../types";
import { LoginFormData } from "./types";
import { api } from "../api";

const API_ENDPOINT_SAML_LOGIN = `${getApiBaseUrl()}/api/v1/auth/saml/login`;
const API_ENDPOINT_LOGIN = `${getApiBaseUrl()}/api/v1/auth/login`;
const API_ENDPOINT_LOGOUT = `${getApiBaseUrl()}/api/v1/auth/logout`;
const API_ENDPOINT_USER = `${getApiBaseUrl()}/api/v1/user`;

export const loginSaml = async () => {
  if (typeof window !== "undefined") {
    window.location.replace(API_ENDPOINT_SAML_LOGIN);
  }
};

export const loginWithCredentials = async (data: LoginFormData): Promise<ApiResponse> => {
  return await api(API_ENDPOINT_LOGIN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const logout = async (): Promise<ApiResponse> => {
  return await api(API_ENDPOINT_LOGOUT, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  });
};

export const getUser = async (): Promise<ApiResponse> => {
  return await api(API_ENDPOINT_USER, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  });
};
