import { useCallback, useState } from "react";
import { useNotifications } from "../../contexts";
import { ApiResponse, AssociationModel } from "../types";
import { putAssociationById } from "./api";
import { IAssociationFormData } from "../association-details";

interface DataEntryHook {
  loading: boolean;
  associationModel: AssociationModel | undefined;
  submitDataEntryForm: (id: string, data: IAssociationFormData) => Promise<ApiResponse<AssociationModel>>;
  formErrors: Record<string, string[]> | null;
  setFormErrors: (errors: Record<string, string[]> | null) => void;
}

export const useDataEntryManager = (): DataEntryHook => {
  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [associationModel, setAssociationModel] = useState<AssociationModel | undefined>();
  const [formErrors, setFormErrors] = useState<Record<string, string[]> | null>(null);

  const submitDataEntryForm = useCallback(
    async (id: string, data: IAssociationFormData): Promise<ApiResponse<AssociationModel>> => {
      if (!id || !data) {
        return;
      }

      setFormErrors(null);
      setAssociationModel(undefined);
      setLoading(true);

      const response = await putAssociationById(id, data);
      if (response.ok && response.data) {
        setAssociationModel(response.data);
        addNotification({
          type: "success",
          title: "Daten gespeichert",
          message: "Die Daten wurden erfolgreich gespeichert",
        });
      } else if (response.error) {
        setFormErrors(response.error.errors || null);
        addNotification({
          type: "error",
          title: "Fehler beim Speichern",
          message: response.error.message || "Fehler beim Speichern der Daten",
        });
      }

      setLoading(false);
      return response;
    },
    [addNotification, setAssociationModel, setFormErrors]
  );

  return {
    loading,
    associationModel,
    submitDataEntryForm,
    formErrors,
    setFormErrors,
  };
};
