import {
  AssociationApprovalCategory,
  AssociationApprovalCategoryEnum,
  AssociationApprovalStatus,
  AssociationApprovalStatusEnum,
  AssociationModel,
} from "../types";

export const categoryToApprovalState = (category: string): AssociationApprovalStatus | null => {
  switch (category) {
    case AssociationApprovalCategoryEnum.Approved:
      return AssociationApprovalStatusEnum.Approved;
    case AssociationApprovalCategoryEnum.Rejected:
      return AssociationApprovalStatusEnum.Rejected;
    case AssociationApprovalCategoryEnum.Open:
      return AssociationApprovalStatusEnum.ReviewStore;
    case AssociationApprovalCategoryEnum.Review:
      return AssociationApprovalStatusEnum.ReviewQa;
    case AssociationApprovalCategoryEnum.Correction:
      return AssociationApprovalStatusEnum.ChangesRequested;
    default:
      return null;
  }
};

export const filterAssociationsByCategory = (
  associations: AssociationModel[],
  category: AssociationApprovalCategory
): AssociationModel[] => {
  return associations.filter(({ approval_state }) => {
    switch (category) {
      case AssociationApprovalCategoryEnum.Open:
        return approval_state === AssociationApprovalStatusEnum.ReviewStore;
      case AssociationApprovalCategoryEnum.Approved:
        return approval_state === AssociationApprovalStatusEnum.Approved;
      case AssociationApprovalCategoryEnum.Rejected:
        return approval_state === AssociationApprovalStatusEnum.Rejected;
      case AssociationApprovalCategoryEnum.Review:
        return approval_state === AssociationApprovalStatusEnum.ReviewQa;
      case AssociationApprovalCategoryEnum.Correction:
        return approval_state === AssociationApprovalStatusEnum.ChangesRequested;
      default:
        return true;
    }
  });
};
