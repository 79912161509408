import { NotificationTypesEnum } from "./interface";

export const resolveIcon = (type: string): string => {
  switch (type) {
    case NotificationTypesEnum.Error:
    case NotificationTypesEnum.Warning:
      return "exclamationCircle";
    case NotificationTypesEnum.Success:
      return "check";
    case NotificationTypesEnum.Info:
    default:
      return "info";
  }
};
