import { useContext, useCallback } from "react";
import { AssociationContext } from "./context";
import {
  AssociationModel,
  AssociationApprovalCategory,
  AssociationApprovalCategoryEnum,
  AssociationApprovalStatusEnum,
} from "../../backend";
import { AssociationContextType } from "./interface";

export const useAssociationContext = (): AssociationContextType => useContext(AssociationContext);

export const useAssociations = (): AssociationModel[] => {
  const { associations } = useAssociationContext();
  return associations;
};

interface GetCategoryCounterFn {
  (category: AssociationApprovalCategory): number;
}

export const useGetAssociationCategoryCounter = (): GetCategoryCounterFn => {
  const { statistics } = useAssociationContext();

  return useCallback(
    (category: AssociationApprovalCategory | string): number => {
      if (!statistics) {
        return 0;
      }
      const total = Object.values(statistics).reduce<number>((prevValue, currentValue) => prevValue + currentValue, 0);

      switch (category) {
        case AssociationApprovalCategoryEnum.Open:
          return statistics[AssociationApprovalStatusEnum.ReviewStore] || 0;
        case AssociationApprovalCategoryEnum.Approved:
          return statistics[AssociationApprovalStatusEnum.Approved] || 0;
        case AssociationApprovalCategoryEnum.Rejected:
          return statistics[AssociationApprovalStatusEnum.Rejected] || 0;
        case AssociationApprovalCategoryEnum.Review:
          return statistics[AssociationApprovalStatusEnum.ReviewQa] || 0;
        case AssociationApprovalCategoryEnum.Correction:
          return statistics[AssociationApprovalStatusEnum.ChangesRequested] || 0;
        default:
          return total;
      }
    },
    [statistics]
  );
};

export const useAssociationWithId = (id: string | number): AssociationModel | null => {
  const { associations } = useAssociationContext();
  return associations.find((item) => `${item.id}` === `${id}`) || null;
};
