export const getApiBaseUrl = (): string => process.env.GATSBY_API_URL as string;

export const getResponseFormErrors = (err: any): Record<string, string[]> | null => {
  if (err.response && err.response.data && typeof err.response.data.errors === "object") {
    return Object.keys(err.response.data.errors).length > 0 ? err.response.data.errors : null;
  }
  return null;
};

export const getResponseFormErrorMessage = (err: any): string | null => {
  if (err.response && err.response.data && typeof err.response.data.message === "string") {
    return err.response.data.message;
  }
  return null;
};

export const getURLSearchParamNumeric = (name: string): number | undefined => {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return undefined;
  }
  const params = new URLSearchParams(document.location.search);
  const value = params.get(name);
  if (!value) {
    return undefined;
  }
  const numValue = parseInt(value);
  if (!Number.isNaN(numValue) && numValue > 0) {
    return numValue;
  }
  return undefined;
};
