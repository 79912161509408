// extracted by mini-css-extract-plugin
export var base = "button-module--base--4b788";
export var disabled = "button-module--disabled--a7236";
export var icon = "button-module--icon--d0382";
export var iconOnly = "button-module--iconOnly--6b4fa";
export var invisible = "button-module--invisible--ffeb8";
export var l = "button-module--l--3cb48";
export var label = "button-module--label--6bb65";
export var m = "button-module--m--2dcfb";
export var primary = "button-module--primary--17ce6";
export var s = "button-module--s--49df7";
export var secondary = "button-module--secondary--c0a70";