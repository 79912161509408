import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./heading.module.scss";

export type HeadingKind = "primary" | "secondary" | "tertiary" | "quarternary" | string;

interface HeadingProps extends Omit<React.HTMLProps<HTMLHeadingElement>, "as"> {
  as?: React.ElementType | undefined;
  kind: HeadingKind;
}

const resolveElementType = (kind: HeadingKind): "h1" | "h2" | "h3" | "h4" => {
  switch (kind) {
    case "primary":
      return "h1";
    case "secondary":
      return "h2";
    case "tertiary":
      return "h3";
    case "quarternary":
      return "h4";
    default:
      return "h2";
  }
};

export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  as,
  children,
  kind = "secondary",
  ...otherProps
}) => {
  const ElementType = as || resolveElementType(kind);
  return (
    <ElementType
      {...otherProps}
      className={classnames({
        [styles.base]: true,
        [styles[kind]]: styles[kind] !== undefined,
      })}
    >
      {children}
    </ElementType>
  );
};
