import * as React from "react";
import { Link } from "gatsby";
import FooterDmLogo from "../../images/footer-dm-logo-claim.svg";
import * as styles from "./footer.module.scss";
import { Icon, Heading } from "../dm";

interface FooterProps {
  pressKitFooter?: boolean; // This prop is optional and of type boolean
}

const socialMap = {
  facebook: "https://www.facebook.com/dmDeutschland",
  instagram: "https://www.instagram.com/dm_deutschland/",
  linkedin: "https://www.linkedin.com/company/dm-drogerie-markt-gmbh-co.-kg/?originalSubdomain=de",
  kununu: "https://www.kununu.com/de/dm-drogerie-markt",
  pinterest: "https://www.pinterest.de/dmdeutschland/",
  tiktok: "https://www.tiktok.com/@dm_deutschland",
  whatsapp: "https://whatsapp.com/channel/0029Va5bU8pDDmFW93XhL220",
  xing: "https://www.xing.com/company/dm",
  youtube: "https://www.youtube.com/user/dmDeutschland",
  newsletter: "https://www.dm.de/services/dm-newsletter",
};

export const Footer: React.FC<FooterProps> = ({ pressKitFooter = false }) => {
  return (
    <>
      {pressKitFooter && (
        <footer className={styles.pressKitBase}>
          <div className={styles.footerWrapper}>
            <Heading kind="secondary">Mit dm verbinden</Heading>
            <div className={styles.socialsWrapper}>
              {Object.entries(socialMap).map(([name, url]) => (
                <a href={url} key={name} target="_blank" rel="noreferrer">
                  <Icon name={name} />
                </a>
              ))}
            </div>
          </div>
          <div style={{ width: "100%", height: "1px", background: "#E5E9F1", marginBottom: "40px" }} />
          <FooterDmLogo className={styles.logo} />
          <div className={styles.pressKitContent}>&copy; 2024 dm-drogerie markt GmbH + Co. KG</div>
        </footer>
      )}

      {!pressKitFooter && (
        <footer className={styles.base}>
          <FooterDmLogo className={styles.logo} />
          <div className={styles.content}>&copy; 2024 dm-drogerie markt GmbH + Co. KG</div>
          <div className={styles.nav}>
            <a href="https://dm.de/impressum" target="_blank" rel="noreferrer">
              Impressum
            </a>
            <Link to="/datenschutz">Datenschutzerklärung</Link>
          </div>
        </footer>
      )}
    </>
  );
};
