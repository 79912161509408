import * as React from "react";
import { AssociationCommentModel, AssociationModel } from "../types";
import { useNotifications } from "../../contexts";
import { AssociationCommentsHook } from "./types";
import { getAssociationComments, postAssociationComment } from "./api";

export const useAssociationComments = (association: AssociationModel): AssociationCommentsHook => {
  const [comments, setComments] = React.useState<AssociationCommentModel[]>(association.comments || []);
  const [pending, setPending] = React.useState<boolean>(false);
  const { addNotification } = useNotifications();
  const associationId = `${association.id}`;

  React.useEffect(() => {
    setComments(association.comments || []);
  }, [association]);

  const getComments = React.useCallback(async (): Promise<AssociationCommentModel[]> => {
    const nextComments = await getAssociationComments(associationId);
    setComments(nextComments);
    return nextComments;
  }, [associationId]);

  const submitComment = React.useCallback(
    async (message: string): Promise<Response> => {
      setPending(true);
      const response = await postAssociationComment(associationId, message);
      if (response.ok) {
        // Reload comment list
        await getComments();
        setPending(false);
        // FIXME: use i18n texts
        addNotification({
          title: "Dein Kommentar wurde gespeichert",
          message: "",
          type: "success",
          lifetime: 6000,
        });
        return response;
      }
      setPending(false);
      addNotification({
        title: `Error ${response.status}`,
        message: response.statusText,
        type: "error",
        lifetime: 6000,
      });

      // TODO: Remove dummy code when backend is accessible
      const now = new Date();
      const dummyComment: AssociationCommentModel = {
        id: now.getTime(),
        user_name: "dm Markt (de-xxxx)",
        message: message,
        created_at: now.toISOString(),
        updated_at: now.toISOString(),
      };
      setComments((prevState) => [...prevState, dummyComment]);
      return new Response(null, { status: 200 }) as Response;
    },
    [associationId, getComments, addNotification]
  );

  React.useEffect(() => {
    getComments();
  }, [getComments]);

  return React.useMemo(
    () => ({
      comments,
      getComments,
      errors: null,
      pending,
      submitComment,
    }),
    [comments, getComments, pending, submitComment]
  );
};
