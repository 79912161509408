import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./cell.module.scss";

export interface CellProps extends React.HTMLProps<HTMLDivElement> {
  as?: string;
  reference?: React.Ref<React.ElementType>;
  noWrap?: boolean;
}

export const Cell = ({ as: Component = "div", className, children, noWrap, reference, ...otherProps }: CellProps) => {
  const Element = (Component || "div") as React.ElementType;
  return (
    <Element
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles.cell]: true,
        [styles.nowrap]: !!noWrap,
      })}
      ref={reference}
    >
      {children}
    </Element>
  );
};
