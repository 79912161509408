import React, { FC, PropsWithChildren, useState } from "react";
import { AssociationReadData } from "../../backend/auth-token/types";
import { AssociationReadDataContext } from "./context";

export const AssociationReadDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [associationReadData, setAssociationReadData] = useState<AssociationReadData | undefined>();

  return (
    <AssociationReadDataContext.Provider value={{ associationReadData, setAssociationReadData }}>
      {children}
    </AssociationReadDataContext.Provider>
  );
};
