/**
 * @param styles {{[className]: boolean}|[string]|string}
 * @return {string}
 */
export const classnames = (styles: Record<string, boolean> | Array<string> | string): string => {
  const stylesArray: string[] = Array.isArray(styles)
    ? styles
    : typeof styles === "object"
      ? Object.entries(styles)
          .filter(([_className, active]) => active)
          .map(([className]) => className)
      : styles.split(" ");
  return stylesArray.filter((className) => className && className.length).join(" ");
};
