/** API Response */

export interface ApiResponse<T = any, E = ApiResponseError> extends Response {
  data?: T;
  error?: E;
}

export interface ApiResponseError extends Record<string, unknown> {
  errors?: Record<string, string[]>;
  message?: string;
}

/** User */

export interface UserModel {
  id: number | string;
  name: string;
  email: string;
  last_login: string;
  store_ids: string[];
  created_at: string;
  updated_at: string;
  roles: UserRoleModel[];
}

export interface UserRoleModel {
  name: UserRoleName;
  permissions: UserPermissionModel[];
}

export type UserRoleName = "store" | "qa" | "gv" | "admin" | string;

export enum UserRoleNameEnum {
  Store = "store",
  Qa = "qa",
  Gv = "gv",
  Admin = "admin",
}

export interface UserPermissionModel {
  name: UserPermissionType;
}

export type UserPermissionType =
  | "list-associations"
  | "create-association"
  | "get-association"
  | "update-association"
  | "list-comments"
  | "create-comment"
  | "transition-to-data-requested"
  | "transition-to-review-store"
  | "transition-to-review-qa"
  | "transition-to-changes-requested"
  | "transition-to-rejected"
  | "transition-to-approved"
  | string;

export enum UserPermissionTypeEnum {
  ListAssociations = "list-associations",
  CreateAssociation = "create-association",
  GetAssociation = "get-association",
  UpdateAssociation = "update-association",
  ListComments = "list-comments",
  CreateComment = "create-comment",
  TransitionToDataRequested = "transition-to-data-requested",
  TransitionToReviewStore = "transition-to-review-store",
  TransitionToReviewQa = "transition-to-review-qa",
  TransitionToChangesRequested = "transition-to-changes-requested",
  TransitionToDataRejected = "transition-to-rejected",
  TransitionToDataApproved = "transition-to-approved",
}

/** Association */

export enum AssociationApprovalStatusEnum {
  ReviewQa = "review-qa",
  ReviewStore = "review-store",
  ChangesRequested = "changes-requested",
  DataRequested = "data-requested",
  Approved = "approved",
  Rejected = "rejected",
}

export type AssociationApprovalStatus =
  | "changes-requested"
  | "data-requested"
  | "review-qa"
  | "review-store"
  | "approved"
  | "rejected"
  | string;

export enum AssociationApprovalCategoryEnum {
  Open = "open",
  Review = "review",
  Correction = "correction",
  Approved = "approved",
  Rejected = "rejected",
}

export type AssociationApprovalCategory = "open" | "review" | "correction" | "approved" | "rejected" | string;

export interface AssociationModel {
  auth_token?: string;
  approval_state?: AssociationApprovalStatus;
  category_org: string;
  city?: string;
  comments?: AssociationCommentModel[];
  created_at?: string;
  donation_purpose_org?: string;
  email: string;
  email_org: string;
  first_name: string;
  gender?: string;
  iban?: string;
  payment_purpose?: string;
  id?: string | number;
  invitation_mail_subject?: string;
  invitation_mail_text?: string;
  is_non_profit?: boolean;
  last_name: string;
  media_logo?: string | undefined;
  media_video?: string | undefined;
  media_image_1?: string | undefined;
  media_image_2?: string | undefined;
  media_image_3?: string | undefined;
  mission_statement_org?: string;
  name_org: string;
  phone_number?: string;
  phone_number_org?: string;
  payment_recipient?: string;
  store_ids?: string;
  street?: string;
  terms_accepted_at?: string;
  title?: string;
  updated_at?: string;
  website_org?: string;
  zip_code?: string;
}

export interface AssociationCommentModel {
  id: number | string;
  user_name: string;
  user_roles: string[];
  created_at: string;
  updated_at: string;
  message: string;
}

export type LimitStatus = "empty" | "partial" | "full" | string;

export enum LimitStatusEnum {
  Empty = "empty",
  Partial = "partial",
  Full = "full",
}

export interface PaginationModel {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

export interface StoreStatisticsModel {
  id: number | string;
  store_id: string;
  association_ids: string;
  email: string;
  city: string;
  street: string;
  gv_name: string;
  invited_associations_count: number;
  invited_associations_necessary_count: number;
  approved_associations_count: number;
  approved_associations_necessary_count: number;
  data_received_associations_count: number;
  data_received_associations_necessary_count: number;
  in_review_associations_count: number;
  in_review_associations_necessary_count: number;
}
