import React from "react";
import { Spacer, Text, Icon } from "../../components";
import { classnames } from "../../utils";
import { resolveIcon } from "../notification/utils";
import * as styles from "./status-info.module.scss";

type StatusInfoType = "info" | "success" | "warning" | "error";

interface StatusInfoProps extends React.HTMLProps<HTMLElement> {
  text?: React.ReactNode;
  type?: StatusInfoType | undefined;
  end?: boolean;
}

export const StatusInfo = ({
  end,
  children,
  className,
  text,
  type,
  ...otherProps
}: React.PropsWithChildren<StatusInfoProps>) => {
  const iconName = resolveIcon(type as string) || "info";

  return (
    <div
      {...otherProps}
      className={classnames({
        [`${className}`]: !!className,
        [styles[type as string]]: styles[type as string] !== undefined,
        [styles.base]: true,
        [styles.end]: !!end,
      })}
    >
      <Icon name={iconName} />
      <Spacer size="xs" />
      {text && <Text>{text}</Text>}
      {children && <Text as="div">{children}</Text>}
    </div>
  );
};
