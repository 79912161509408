import axios from "axios";
import { AssociationModel } from "../../contexts/association";
import { ApiResponse } from "../types";
import { IAssociationFormData, IAssociationFormResponse } from "../association-details";
import { getApiBaseUrl } from "../utils";

const UPDATE_ASSOCIATION_API_URL = `${getApiBaseUrl()}/api/v1/association`;

export const putAssociationById = async (
  id: string,
  data: IAssociationFormData
): Promise<ApiResponse<AssociationModel>> => {
  const form = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "is_non_profit") {
      value = value ? 1 : 0;
    }
    form.append(key, value as string | File);
  });
  form.append("_method", "patch");

  const options = {
    method: "POST",
    url: `${UPDATE_ASSOCIATION_API_URL}/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    data: form,
  };

  let response: ApiResponse<AssociationModel>;
  try {
    const res = await axios.request(options);
    if (res && res.data) {
      response = {
        ...(res as Response),
        ok: true,
        data: res.data as IAssociationFormResponse,
        error: undefined,
      };
    }
  } catch (err) {
    response = {
      ...err.response,
      ok: false,
      data: undefined,
      error: err.response?.data,
    };
  }
  return response;
};
