import * as React from "react";
import { classnames } from "../../../utils";
import * as styles from "./spacer.module.scss";

export interface SpacerProps extends Omit<React.HTMLProps<HTMLDivElement>, "size" | "children"> {
  size: string;
}

export const Spacer: React.FC<SpacerProps> = ({ size, ...otherProps }) => {
  return (
    <div
      {...otherProps}
      className={classnames({
        [styles.base]: true,
        [styles[size]]: styles[size] !== undefined,
      })}
    />
  );
};
