import React from "react";
import { classnames } from "../../../utils";
import { TooltipPlacement, TooltipPlacementEnum } from "./types";
import * as styles from "./tooltip-arrow.module.scss";

export interface TooltipArrowProps {
  placement?: TooltipPlacement;
  reference?: React.Ref<HTMLDivElement> | string | null;
}

export const TooltipArrow = ({ placement, reference }: TooltipArrowProps) => {
  return (
    <div
      className={classnames({
        [styles.arrow]: true,
        [styles.up]: placement?.startsWith(TooltipPlacementEnum.Bottom) || false,
        [styles.down]: placement?.startsWith(TooltipPlacementEnum.Top) || false,
        [styles.left]: placement ? /^right/.test(placement) : false,
        [styles.right]: placement ? /^left/.test(placement) : false,
        [styles.end]: placement?.endsWith("end") || false,
        [styles.start]: placement?.endsWith("start") || false,
      })}
      ref={reference}
    >
      <div className={styles.border} />
      <div className={styles.fill} />
    </div>
  );
};
