import * as React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { UserRoleNameEnum } from "../../backend";
import { useUser } from "../../contexts";
import { classnames } from "../../utils";
import { Icon } from "../dm";
import * as styles from "./navbar.module.scss";

export const Navbar: React.FC = () => {
  const { user, role, pending } = useUser();
  const { pathname } = useLocation();

  const renderAccountLink = (className: string) => {
    if (pending) {
      return null;
    }
    // TODO: use i18n texts
    if (user) {
      return (
        <Link className={className || styles.link} activeClassName={styles.active} partiallyActive to="/user/login">
          <Icon name="userCheck" />
          <span className={styles.desktopOnly}>
            {user.name} <sup>{role}</sup>
          </span>
        </Link>
      );
    }
    return pathname === "/downloads-partnerprojekte/" ? null : (
      <Link className={className || styles.link} activeClassName={styles.active} partiallyActive to="/user/login">
        <Icon name="user" />
        Login
      </Link>
    );
  };

  return (
    <nav className={styles.base}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <Link to="/">
            {pathname === "/downloads-partnerprojekte/" ? (
              <img
                className={styles.pressKitLogo}
                src="/images/lust-an-zukunft-presskit-logo-100x102.png"
                alt="Lust an Zukunft Logo"
                width={100}
                height={102}
              />
            ) : (
              <img
                className={styles.logo}
                src="/images/lust-an-zukunft-logo-440x440.webp"
                alt="Lust an Zukunft Logo"
                width={110}
                height={110}
              />
            )}
          </Link>
        </div>
        <div className={styles.nav}>
          {(role === UserRoleNameEnum.Store || role === UserRoleNameEnum.Qa || role === UserRoleNameEnum.Admin) && (
            <>
              <Link
                className={classnames({
                  [styles.link]: true,
                  [styles.active]: pathname.startsWith("/association"),
                })}
                to="/association/overview"
              >
                Initiativen
              </Link>
            </>
          )}
          {(role === UserRoleNameEnum.Gv || role === UserRoleNameEnum.Qa || role === UserRoleNameEnum.Admin) && (
            <>
              <Link className={styles.link} to="/store/overview" activeClassName={styles.active} partiallyActive>
                Filialen
              </Link>
            </>
          )}
          <Link
            className={pathname !== "/downloads-partnerprojekte/" ? styles.link : styles.pressKitLink}
            to="/hilfe-und-support"
            activeClassName={styles.active}
            partiallyActive
          >
            Hilfe &amp; Support
          </Link>
          <Link
            className={pathname !== "/downloads-partnerprojekte/" ? styles.link : styles.pressKitLink}
            to="/teilnahmebedingungen"
            activeClassName={styles.active}
            partiallyActive
          >
            Teilnahmebedingungen
          </Link>
          {renderAccountLink(classnames([styles.link, styles.mobile]))}
        </div>
        <div className={styles.right}>{renderAccountLink(styles.link)}</div>
      </div>
    </nav>
  );
};
