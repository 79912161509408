import * as React from "react";
import ContextWrapper from "./src/contexts/ContextWrapper";

import "./static/css/base.css";
import "./static/css/theme.css";
import "./static/css/global.scss";

export const wrapRootElement = ({ element }) => {
  return <ContextWrapper>{element}</ContextWrapper>;
};

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (!prevRouterProps || !routerProps) {
    return;
  }
  const { location: prevLocation } = prevRouterProps;
  const { location: nextLocation } = routerProps;
  const { pathname: prevPathname } = prevLocation;
  const { pathname: nextPathname } = nextLocation;

  const prevPathSegments = prevPathname.slice(1).split("/").slice(0, 2);
  const nexPathSegments = nextPathname.slice(1).split("/").slice(0, 2);

  if (prevPathSegments.toString() === nexPathSegments.toString()) {
    return false;
  }
  return true;
};
