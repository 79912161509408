import { ApiResponse } from "../types";
import { getApiBaseUrl } from "../utils";
import { api } from "../api";
import { StoreList, StoreGvList } from "./types";

const API_ENDPOINT_LIST = `${getApiBaseUrl()}/api/v1/store/statistics`;
const API_ENDPOINT_GVS = `${getApiBaseUrl()}/api/v1/store/gvs`;
const API_ENDPOINT_EXPORT = `${getApiBaseUrl()}/api/v1/store-export`;

export const getStoreList = async (query?: Record<string, string | number>): Promise<ApiResponse<StoreList> | null> => {
  let url = API_ENDPOINT_LIST;
  const params = new URLSearchParams();
  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      params.set(key, `${value}`);
    });
    url = `${API_ENDPOINT_LIST}?${params.toString()}`;
  }
  let res: ApiResponse<StoreList> | null = null;
  try {
    res = await api<StoreList>(url, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
  return res;
};

export const getGvList = async (): Promise<ApiResponse<StoreGvList> | null> => {
  let res: ApiResponse<StoreGvList> | null = null;
  try {
    res = await api<StoreGvList>(API_ENDPOINT_GVS, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
  return res;
};

export const getStoreStatisticsExportUrl = (query?: Record<string, string | number>): string => {
  let url = API_ENDPOINT_EXPORT;
  const params = new URLSearchParams();
  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      params.set(key, `${value}`);
    });
    url = `${API_ENDPOINT_EXPORT}?${params.toString()}`;
  }
  return url;
};
